import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {PanelModule} from 'primeng/panel';
import { RouterModule } from '@angular/router';
import { TableModule } from 'primeng/table';
import {MultiSelectModule} from 'primeng/multiselect';
import {CalendarModule} from 'primeng/calendar';
import {TabViewModule} from 'primeng/tabview';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

import { HOME_ROUTE } from './home.route';
import { HomeComponent } from './login/home.component';
import { OlMapComponent } from './map/ol-map.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DuplosSharedModule } from '../shared/shared.module';
import { PersonalDataComponent } from './personal-data/personal-data.component';
import { MentionsLegalesComponent } from './mentions-legales/mentions-legales.component';

@NgModule({
    imports: [
        DuplosSharedModule,
        PanelModule,
        TableModule,
        TabViewModule,
        CalendarModule,
        MultiSelectModule,
        ButtonModule,
        DialogModule,
        ConfirmDialogModule,
        RouterModule.forChild([HOME_ROUTE])
    ],
    declarations: [
        HomeComponent,
        DashboardComponent,
        OlMapComponent,
        PersonalDataComponent,
        MentionsLegalesComponent,
    ],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DuplosHomeModule {}
