<h2 class="fs-title">{{typeDeclaration && typeDeclaration.code=='1'?'Déclaration':'Régularisation'}} d'un projet d'ouvrages souterrains</h2>

<div class="row form-row">
    <label class="col-md-5" for="projectName">Nom du projet <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group">
    <input type="text" class="col-md-6 form-control" id="projectName" name="projectName" maxlength="80" [(ngModel)]="declaration.nomProjet" />
</div>

<div class="row form-row" *ngIf="typeReglementation != 'FORDOM'">
    <label class="col-md-5">Nombre d'ouvrages prévus <span class="asterisk-red">*</span></label>
</div>
<div class="row form-row form-group" *ngIf="typeReglementation != 'FORDOM'">
    <ng-select id="plusieursOuvrages" name="plusieursOuvrages" (change)="changeNbOuvrages($event)" [(ngModel)]="declaration.plusieursOuvrages" class="col-md-6 dropdown-no-padding">
        <ng-option></ng-option>
        <ng-option [value]="false">Un seul</ng-option>
        <ng-option [value]="true">Plusieurs</ng-option>
    </ng-select>
</div>
<div class="row form-row" *ngIf="declaration.plusieursOuvrages && typeReglementation != 'FORDOM'">
    <label class="col-md-5 form-control-label" for="field_relation">Relation entre les ouvrages <span class="asterisk-red">*</span></label>
</div>
<div class="row form-row form-group" *ngIf="declaration.plusieursOuvrages && typeReglementation != 'FORDOM'">
    <ng-select id="field_relation" name="relation" [(ngModel)]="declaration.relationId" class="col-md-6 dropdown-no-padding">
        <ng-option [value]="lexRelationOption.id" *ngFor="let lexRelationOption of lexrelations" [hidden]="!lexRelationOption.valide">{{lexRelationOption.libelle}}</ng-option>
    </ng-select>
</div>

<h4>{{typeDeclaration && typeDeclaration.code=='1'?'Période envisagée des travaux':'Période des travaux réalisés'}}</h4>
<div class="row form-row">
    <label class="col-md-5 form-control-label" for="field_dateDebutTravaux">Date Début Travaux <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group">
    <p-calendar id="field_dateDebutTravaux" class="col-md-6 dropdown-no-padding" [minDate]="minDate" [maxDate]="maxSelectableDate" [showIcon]="true" inputStyleClass="form-control form-control-calendar" [(ngModel)]="declaration.dateDebutTravaux" name="dateDebutTravaux"
    dateFormat="dd/mm/yy"  (onSelect)="onDateDebutSelect($event)" appendTo="body" [baseZIndex]="1040"></p-calendar>
</div>
<div class="row form-row">
    <label class="col-md-5 form-control-label" for="field_dateFinTravaux">Date Fin Travaux <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group">
    <p-calendar id="field_dateFinTravaux" class="col-md-6 dropdown-no-padding" [minDate]="minSelectableDate" [maxDate]="maxDate" [showIcon]="true"
     inputStyleClass="form-control form-control-calendar" [(ngModel)]="declaration.dateFinTravaux" name="dateFinTravaux"
    dateFormat="dd/mm/yy"  (onSelect)="onDateFinSelect($event)" appendTo="body" [baseZIndex]="1040"></p-calendar>
</div>
<span *ngIf="!isDebutOK" style="color: red;">Veuillez corriger la date de début des travaux qui doit être dans le futur</span>
<span *ngIf="!isFinOK" style="color: red;">Veuillez corriger la date de fin des travaux qui doit être dans le futur</span>
<!--span *ngIf="isDebutTooSoon" style="color: red;">Vous avez saisi une date de début des travaux dans moins d’un mois. <br/>Ce délai risque d'être insuffisant pour l'instruction de votre dossier.</span-->
<span *ngIf="isFinKO" style="color: red;">Veuillez corriger les dates des travaux afin d’avoir une date de fin postérieure à la date de début</span>

<h4>Objectif des ouvrages</h4>

<div class="row form-row">
    <label class="col-md-5 form-control-label" for="field_fonction">Fonction <span class="asterisk-red" *ngIf="typeReglementation == 'MIN'">*</span>
    <span *ngIf="typeReglementation == 'FORDOM'">: Exploitation - Eau - Usage domestique</span>
    </label>
</div>

<div class="row form-row" *ngIf="typeReglementation == 'FORDOM'">
    <label class="col-md-5" >Usage de l'eau<span class="asterisk-red">*</span></label>
</div>
<div class="row form-row form-group" *ngIf="typeReglementation == 'FORDOM'">
    <!--ng-select class="col-md-6 dropdown-no-padding"  bindLabel="text" name="autreUsage" id="autreUsageEau" [(ngModel)]="declaration.autreUsagePrecision" (change)="updateUsagesFordom($event)">
        <ng-option value="ARROSAGE">ARROSAGE</ng-option>
        <ng-option value="ABREUVAGE">ABREUVAGE</ng-option>
        <ng-option value="SANITAIRE">SANITAIRE</ng-option>
        <ng-option value="LAVAGE-NETTOYAGE">LAVAGE-NETTOYAGE</ng-option>
        <ng-option value="PISCINE-BASSIN">PISCINE-BASSIN</ng-option>
        <ng-option value="ALIMENTAIRE">ALIMENTAIRE</ng-option>
    </ng-select-->
    <div class="checkbox_box">
        <p>
            <label for="arrosage">ARROSAGE</label><input id="arrosage" type="checkbox" [(ngModel)]="arrosage" (change)="updateUsagesFordom($event)" />
            <label for="lavagenettoyage">LAVAGE-NETTOYAGE</label><input id="lavagenettoyage" type="checkbox" [(ngModel)]="lavageNettoyage" (change)="updateUsagesFordom($event)"  />
        </p>
        <p>
            <label for="abreuvage">ABREUVAGE</label><input id="abreuvage"  type="checkbox" [(ngModel)]="abreuvage" (change)="updateUsagesFordom($event)"  />
            <label for="piscinebassin">PISCINE-BASSIN</label><input id="piscinebassin" type="checkbox" [(ngModel)]="piscineBassin" (change)="updateUsagesFordom($event)"  />
            
        </p>
        <p>
            <label for="sanitaire">SANITAIRE</label><input id="sanitaire"  type="checkbox" [(ngModel)]="sanitaire" (change)="updateUsagesFordom($event)"  />
            <label for="consommationhumaine">CONSOMMATION-HUMAINE</label><i class="fas fa-info-circle" [title]="tooltip()"></i><input id="consommationhumaine" type="checkbox" [(ngModel)]="consommationHumaine" (change)="updateUsagesFordom($event)" title="Au sens de l’article R. 1321-1 du code de la santé publique" />
        </p>
        <p *ngIf="this.consommationHumaine">
            <span>En cas d’utilisation de l’eau pour la consommation humaine : pour un usage unifamilial , une analyse de l’eau de type P1, à l’exception du chlore, définie dans l’arrêté du 11 janvier 2007 (relatif au programme de prélèvements et d’analyses du contrôle sanitaire pour les eaux fournies par un réseau de distribution) doit être réalisée et jointe à la déclaration ; pour les ouvrages à réaliser l’analyse est transmise après travaux ;
             <br/>
             pour les autres cas, une autorisation préfectorale doit être demandée au titre de l’article L.1321-7 du code de la santé publique.
            </span>
            <!--<label for="">USAGE UNIFAMILIAL</label><input id="sanitaire"  type="checkbox" [(ngModel)]="unifamilial" (change)="updateUsagesFordom($event)"  />
            <label for="">AUTRE CAS D'USAGE</label><input id="sanitaire"  type="checkbox" [(ngModel)]="autreCasUsage" (change)="updateUsagesFordom($event)"  />
                -->
        </p>
    </div>
</div>
    
<div class="row form-row form-group" *ngIf="typeReglementation == 'MIN'">
    <ng-select class="col-md-6 dropdown-no-padding"  [items]="fonctionsOptions" bindLabel="text" name="fonction" id="field_fonction" [(ngModel)]="selectedFonction" (change)="fonctionChange($event, false)"></ng-select>
</div>
<div class="row form-row form-group" *ngIf="typeReglementation == 'FORDOM'" [hidden] = "typeReglementation == 'FORDOM'">
    <ng-select class="col-md-6 dropdown-no-padding" [disabled]="true" [items]="fonctionsOptions" bindLabel="text" name="fonction" id="field_fonction" [(ngModel)]="selectedFonction" (change)="fonctionChange($event, false)"></ng-select>
</div>
<div class="row form-row form-group" *ngIf="typeReglementation == 'MIN'">
    <div class="col-md-6 dropdown-no-padding" *ngIf="selectedFonction && selectedFonction.children.length > 0">
        <ng-select [items]="selectedFonction.children" bindLabel="text" name="fonction_child" id="field_fonction_child" [(ngModel)]="selectedFonctionFinal" (change)="fonctionChange($event, true)"></ng-select>
    </div>
    <span style="color: red;" *ngIf="typeReglementation == 'MIN'  && showPrelevementWarning">Attention ! Si votre prélèvement d'eau est inférieur à 1000 m3, vous devez utilisez le menu "Déclarer forage domestique"</span>
</div>
<div class="row form-row form-group" *ngIf="typeReglementation == 'FORDOM'" [hidden] = "typeReglementation == 'FORDOM'">
    <div class="col-md-6 dropdown-no-padding" *ngIf="selectedFonction && selectedFonction.children.length > 0">
        <ng-select [items]="selectedFonction.children" [disabled]="true" bindLabel="text" name="fonction_child" id="field_fonction_child" [(ngModel)]="selectedFonctionFinal" (change)="fonctionChange($event, true)"></ng-select>
    </div>
</div>
<div class="row" *ngIf="isExploit && typeReglementation == 'MIN'">
    <label class="col-md-5 form-control-label" for="field_usage">Usage de l'eau <span class="asterisk-red">*</span></label>
</div>
<div class="row form-row form-group" *ngIf="isExploit && typeReglementation == 'MIN'">
    <dup-hierarchical-select class="col-md-6 dropdown-no-padding" (onHierarchicalSelectChange)="usageChange($event)" [options]="usagesOptions" [selectedOption]="selectedUsage" [requiredUsage]="true"></dup-hierarchical-select>
</div>

<div class="row form-row" *ngIf="isSubstance">
    <label class="col-md-5 form-control-label" for="field_fonction">Substance <span class="asterisk-red">*</span></label>
</div><div class="row form-row form-group" *ngIf="isSubstance">
    <ng-select class="col-md-6 dropdown-no-padding" [items]="substancesOptions" bindLabel="text" name="substance" id="field_substance" [(ngModel)]="selectedSubstance" (change)="parentSubstanceChange($event)"></ng-select>
</div>
<div class="row form-row form-group" *ngIf="selectedSubstance">
    <ng-select class="col-md-6 dropdown-no-padding" [items]="selectedSubstance.children" bindLabel="text" name="substance_child" id="field_substance_child" (change)="substanceChange($event)" [(ngModel)]="selectedSubstanceFinal"></ng-select>
</div>

<!--<div class="row form-row" *ngIf="isEau">
    <label class="col-md-5 form-control-label" for="field_prelevement">Prelevement global envisagé inférieur à 1000 m<sup>3</sup>/an ?</label>
    <input type="checkbox" class="col-md-1" name="domestique" id="domestique" [(ngModel)]="isDomestique" (change)="parentDomestique()" />
</div>-->

<!--<div class="row form-row">
    <label class="col-md-5 form-control-label" for="concerneIcpe">Concerne une ICPE ? <span class="asterisk-red">*</span> (<a href="https://www.georisques.gouv.fr/risques/installations" target="_blank">Plus d'infos</a>)</label>
</div><div class="row form-row form-group">
    <ng-select id="concerneIcpe" class="col-md-6 dropdown-no-padding" name="concerneIcpe" (change)="changeICPE($event)" [(ngModel)]="declaration.typeIcpeId" placeholder="Non">
        <ng-option [value]="lexTypeIcpeOption.id" *ngFor="let lexTypeIcpeOption of lextypeicpes">{{lexTypeIcpeOption.libelle}}</ng-option>
    </ng-select>
</div>

<div class="row form-row" *ngIf="isICPE">
    <label class="col-md-5 form-control-label" for="field_regimeIcpe">Regime ICPE <span class="asterisk-red">*</span> (<a href="https://www.https://www.georisques.gouv.fr/articles-risques/les-installations-classees-pour-la-protection-de-lenvironnement#regime-de-classement-des-installations-classees" target="_blank">Plus d'infos</a>)</label>
</div><div class="row form-row form-group" *ngIf="isICPE">
    <ng-select id="field_regimeIcpe" class="col-md-6 dropdown-no-padding" name="regimeIcpe" [(ngModel)]="declaration.regimeIcpeId">
        <ng-option [value]="lexRegimeIcpeOption.id" *ngFor="let lexRegimeIcpeOption of lexregimeicpes">{{lexRegimeIcpeOption.libelle}}</ng-option>
    </ng-select>
</div>-->
