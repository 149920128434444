import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';

import * as $ from 'jquery';
import * as _ from 'lodash';

import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { OuvragePopupService } from '../../shared/popup/ouvrage-popup.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { LexNatureOuvrage } from 'src/app/entities/lex-nature-ouvrage/lex-nature-ouvrage.model';
import { LexDirectionOuvrage } from 'src/app/entities/lex-direction-ouvrage/lex-direction-ouvrage.model';
import { LexNatureOuvrageService } from 'src/app/entities/lex-nature-ouvrage/lex-nature-ouvrage.service';
import { LexDirectionOuvrageService } from 'src/app/entities/lex-direction-ouvrage/lex-direction-ouvrage.service';

@Component({
  selector: 'dup-carac-ouvrage',
  templateUrl: './caracouvrageform.component.html',
  styleUrls: [
    '../forms.css'
  ]
})
export class CaracOuvrageFormComponent implements OnInit, OnChanges {
  @Input() ouvrage: Ouvrage;

  //@Input() isDomestique: Boolean;
  @Input() isEau: Boolean;

  lexnaturesouvrage:LexNatureOuvrage[];
  lexdirections:LexDirectionOuvrage[];

  codeDirection:string;

  isValidLongueur:Boolean;
  isValidAzimut:Boolean;
  isValidPendage:Boolean;
  isValidMaxVolume:Boolean;

  isValidPrelevement:Boolean
  isValidDebit:Boolean;

  // est-ce que la sauvegarde est en cours
  isSaving: Boolean = false;

  isDomestique: Boolean;

  constructor(
      private alertService: AlertService,
      //private ngbModal: NgbActiveModal,
      private lexNatureOuvrageService: LexNatureOuvrageService,
      private lexDirectionOuvrageService: LexDirectionOuvrageService
  ) {
    this.isValidAzimut = true;
    this.isValidLongueur = true;
    this.isValidPendage = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if(propName == 'ouvrage')
      {
        this.maxLongueur(null);
        this.maxAzimut(null);
        this.maxPendage(null);
      }
    }
  }

  ngOnInit(): void {
    if (sessionStorage.getItem("typeReglementation") && sessionStorage.getItem("typeReglementation") == "FORDOM"){
      this.isDomestique = true;
    } else {
      this.isDomestique = false;
    }

    this.lexNatureOuvrageService.query()
      .subscribe((res) => { 
        let ids = [2200, 2250];
        if(this.isDomestique){
          ids.push(2300);
        }else{
          ids.push(2150);
        }
        this.lexnaturesouvrage = res.filter(e=> ids.includes(e.id) ).sort(function(a, b) { return a.mnemo.localeCompare(b.mnemo) })  ;
      }, (res) => this.onError(res));
    this.lexDirectionOuvrageService.query()
      .subscribe((res) => {
        this.lexdirections = res;
        if(this.ouvrage.directionId) {
          let direction = _.find(this.lexdirections, ['id', this.ouvrage.directionId]);
          this.codeDirection = direction.code;
        }
       }, (res) => this.onError(res));
       this.validateEau();
  }

    // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }

  validateEau() {
    this.isValidDebit = this.ouvrage.debit == null || this.ouvrage.debit >= 0 ;
    this.isValidPrelevement = this.ouvrage.prelevement == null || this.ouvrage.prelevement >= 0;
  }

  maxLongueur(event) {
    if(this.ouvrage && this.ouvrage.longeur && this.ouvrage.longeur >= 0 && this.ouvrage.longeur <= 9999) {
      this.isValidLongueur = true;
    } else {
      this.isValidLongueur = false;
    }
    if(!this.ouvrage || !this.ouvrage.longeur) {
      this.isValidLongueur = true;
    }
  }

  maxAzimut(event) {
    if(this.ouvrage && this.ouvrage.azimut && this.ouvrage.azimut >= 0 && this.ouvrage.azimut < 360) {
      this.isValidAzimut = true;
    } else {
      this.isValidAzimut = false;
    }
    if(!this.ouvrage || !this.ouvrage.azimut) {
      this.isValidAzimut = true;
    }
  }

  maxPendage(event) {
    if(this.ouvrage && this.ouvrage.pendage && this.ouvrage.pendage >= 0 && this.ouvrage.pendage <= 90) {
      this.isValidPendage = true;
    } else {
      this.isValidPendage = false;
    }
    if(!this.ouvrage || !this.ouvrage.pendage) {
      this.isValidPendage = true;
    }
  }

  changeDirection(event) {
    let direction = _.find(this.lexdirections, ['id', this.ouvrage.directionId]);
    this.codeDirection = direction.code;
    this.ouvrage.directionLibelle = direction.libelle;
  }

  changeNature(event) {
    let nature = _.find(this.lexnaturesouvrage, ['id', this.ouvrage.natureOuvrageId]);
    this.ouvrage.natureOuvrageLibelle = nature.libelle;
  }

  validateOuvrage() {
    let baseOuvrage = this.ouvrage.longeur != null && this.ouvrage.longeur <10000 && this.ouvrage.natureOuvrageId != null && this.ouvrage.directionId != null;
    if(this.ouvrage.directionId == 2400) baseOuvrage = baseOuvrage && this.ouvrage.azimut != null && this.ouvrage.azimut>=0 && this.ouvrage.azimut<360 && this.ouvrage.pendage != null && this.ouvrage.pendage>=0 && this.ouvrage.pendage <= 90;
      /*if(this.isDomestique) baseOuvrage = baseOuvrage && this.ouvrage.diametre != null && this.ouvrage.presenceMargelle != null && this.ouvrage.conformeNorme != null
        && this.ouvrage.prelevement != null && this.ouvrage.debit != null && this.ouvrage.rejetUsees != null && this.ouvrage.reseauInterne != null && this.ouvrage.rejetPluviales != null;*/
    return !baseOuvrage;
  }

  checkMaxVolumeFORDOM(){
    console.log("Dans le checkMaxVolume");
    this.isValidMaxVolume = this.ouvrage.prelevement == null || (( (this.ouvrage.prelevement < 1000 || !this.isDomestique) && this.ouvrage.prelevement >=0));
  }
}

@Component({
  selector: 'dup-ouvrage-popup',
  template: ''
})
export class CaracOuvragePopupComponent implements OnInit, OnDestroy {

  routeSub: any;

  constructor(
    private route: ActivatedRoute,
    private ouvragePopupService: OuvragePopupService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.params.subscribe((params) => {
        if ( params['id'] ) {
            this.ouvragePopupService
                .open(CaracOuvrageFormComponent as Component, params['id']);
        } else {
            this.ouvragePopupService
                .open(CaracOuvrageFormComponent as Component);
        }
    });
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

}
