import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild,  SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { Declaration } from 'src/app/entities/declaration/declaration.model';
import { FileUpload } from 'primeng/fileupload';
import {ConfirmationService} from 'primeng/api'; 
import { ModeleTypeInfoComplPopupComponent } from 'src/app/shared/popup/modeletypeinfocompl-popup.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModeleTypeInfoComplPopupService } from 'src/app/shared/popup/modeletypeinfocompl-popup.service';

@Component({
  selector: 'dup-complement-declaration-form',
  templateUrl: './complementdeclarationform.component.html',
  styleUrls: [
    '../forms.css',
    './complementdeclarationform.css'
  ],
  providers:[ConfirmationService]
})
export class ComplementDeclarationFormComponent implements OnInit, OnChanges {

  @Input() declaration: Declaration;
  @Input() rapportAlreadyUploaded: boolean;
  @Output() isComplementValid = new EventEmitter();
  @Output() fileDataUpload = new EventEmitter();


  @ViewChild('fileUploader') fileUploader: FileUpload;

  fileData:FormData;

  askToUpload: boolean = false;
  askToUploadAttestation :boolean = false;
  askNoFile: boolean = false;
  radiofileToUpload: number = 0;

  errorFileSize: boolean = false;
  errorFileName: boolean = false;

  fileInfo:string = "";


  modeleTypeVisible: boolean = false; // visibilité de la popup du modèle type

  constructor(private modeleTypeInfoComplPopupService: ModeleTypeInfoComplPopupService) {
  }

  ngOnInit(): void {
    this.computeDefaultCheckBox();
  }

  computeDefaultCheckBox(){
    this.askToUpload = this.rapportAlreadyUploaded;
    this.askNoFile = this.declaration?.id != null && this.rapportAlreadyUploaded == false;
    this.computeValidate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {  
      if(propName == 'rapportAlreadyUploaded') {
        this.computeDefaultCheckBox();
      }
    }
  }

  onSelectUpload(event, uploader: FileUpload){

    this.errorFileSize = (event.files[0] as File).size > 10485760;
    this.errorFileName = (event.files[0] as File).name.length > 80;

    if (this.errorFileSize || this.errorFileName){
      uploader.remove(event, 0);
      this.askToUpload = false;
      this.isComplementValid.emit(false);
    } else {
      this.askNoFile = false;
      this.askToUpload = true;
    }
  }

  myUploader(event, uploader: FileUpload) {
    if(this.fileData) {
        uploader.remove(event, 0);
        this.fileData = null;
        (this.fileUploader as any).chooseIcon = ""
        this.computeValidate();
        this.fileDataUpload.emit(null);
        this.fileInfo ="";
    } else{
        this.fileData = new FormData();
        this.fileData.append("fichier",event.files[0] as File, (event.files[0] as File).name);
        this.fileData.append("declarationId",this.declaration.id.toString());
        this.fileData.append("nomFichier", (event.files[0] as File).name);
        this.fileInfo = this.formatBytes((event.files[0] as File).size);
        (this.fileUploader as any).chooseIcon = "pi pi-check";
        this.computeValidate();
        this.isComplementValid.emit(true);
        this.fileDataUpload.emit(this.fileData);
    }
  }

  formatBytes(bytes) {
    if (!+bytes) return '0 Octet'

    const k = 1024
    const sizes = ['Octets', 'Ko', 'Mo']
    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`
  }

  handleClick(checkbox){
    if(checkbox){
      this.askNoFile = false;
    }
    this.computeValidate();
  }

  handleNoFileClick(checkbox){
    if(checkbox){
      this.askToUpload = false;
      this.fileData = null;
      this.fileUploader.clear();
      this.fileDataUpload.emit(null);
    }
    this.computeValidate();
  }

  onCancelFileUpload(){
    this.askToUpload = false;
    this.computeValidate();
    this.fileDataUpload.emit(null);
  }

  computeValidate(){
    let isValid = false;
    if(this.radiofileToUpload == 1 && this.fileData){
      isValid = true;
    }else if(this.radiofileToUpload == 2 && this.fileData && this.declaration.commentaire?.length > 0) {
      isValid = true;
    }
    
    this.isComplementValid.emit(isValid);
  }

  // Gère l'affichage du modèle type lorsque l'utilisateur click sur "(modèle type)"
  displayModeleType(){
    let modalRef: NgbModalRef = this.modeleTypeInfoComplPopupService.modeleTypeModalRef(ModeleTypeInfoComplPopupComponent as Component);
  }
}
