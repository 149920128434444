import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Utilisateur } from './utilisateur.model';
import { ListUtilisateur } from './listutilisateur.model';
import { SERVER_API_URL } from 'src/app/app.constants';

@Injectable()
export class UtilisateurService {

    private resourceUrl =  SERVER_API_URL + 'api/utilisateurs';

    constructor(private http: HttpClient) { }

    create(utilisateur: Utilisateur): Observable<Utilisateur> {
        return this.http.post(this.resourceUrl, utilisateur).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(utilisateur: Utilisateur): Observable<Utilisateur> {
        return this.http.put(this.resourceUrl, utilisateur).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<Utilisateur> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByMail(mail: string): Observable<Utilisateur> {
        return this.http.get(`${this.resourceUrl}/mail/${mail}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    query(req?: any): Observable<ListUtilisateur> {
        return this.http.get(this.resourceUrl, {params: req, observe: 'response'})
        .pipe(map((res) => {
            let listUtilisateurs = new ListUtilisateur();
            listUtilisateurs.utilisateurs = this.convertResponse(res.body);
            listUtilisateurs.maxUtilisateur = Number(res.headers.get('X-Total-Count'));
            return listUtilisateurs;
        }));
    }

    queryForValidationCommunal(req?: any): Observable<ListUtilisateur> {
        return this.http.get(`${this.resourceUrl}/communal`, {params: req, observe: 'response'})
        .pipe(map((res) => {
            let listUtilisateurs = new ListUtilisateur();
            listUtilisateurs.utilisateurs = this.convertResponse(res.body);
            listUtilisateurs.maxUtilisateur = Number(res.headers.get('X-Total-Count'));
            return listUtilisateurs;
        }));
    }

    export(req?: any): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(this.resourceUrl+'/export', { headers: headers, params: req, responseType: 'blob' });

    }
    exportCommunal(): Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(this.resourceUrl+'/communal/export', { headers: headers, responseType: 'blob' });

    }
    deleteExport(): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/export/deletefile`);
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    updateZones(id:number, regionIds:number[], departementIds:number[]): Observable<any> {
        return this.http.post(`${this.resourceUrl}/${id}/zone`, {regionIds: regionIds, departementIds: departementIds});
    }

    isAdmin(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/admin`).pipe(map((res) => {
            return res;
        }));
    }

    isBSS(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/bss`).pipe(map((res) => {
            return res;
        }));
    }

    isState(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/state`).pipe(map((res) => {
            return res;
        }));
    }

    isRegion(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/region`).pipe(map((res) => {
            return res;
        }));
    }

    isPro(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/pro`).pipe(map((res) => {
            return res;
        }));
    }

    isSelectionDepartementale(id: number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/selectionDepartementale`).pipe(map((res) => {
            return res;
        }))
    }

    getZones(id:number): Observable<any> {
        return this.http.get(`${this.resourceUrl}/${id}/zones`).pipe(map((res) => {
            return res;
        }));
    }

    sendDemandeProfil(profilId:number){
        return this.http.post(`${this.resourceUrl}/demandeProfil`, profilId).pipe(map((res) => {
            return res;
        }));
    }

    private convertResponse(res): Utilisateur[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(res[i]);
        }
        return result;
    }

    /**
     * Convert a returned JSON object to Utilisateur.
     */
    private convertItemFromServer(json: any): Utilisateur {
        const entity: Utilisateur = Object.assign(new Utilisateur(), json);
        /*entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);*/
        return entity;
    }
}
