  <h2 class="modal-title" style="padding-left: 1rem;">{{ nomOuvrage ? nomOuvrage : 'Nom Ouvrage' }}</h2>
  <h2 class="modal-title modal-header" >Aquifère, eau superficielle ou source envisagé</h2>

<div class="saving" [hidden]="!isLoading">
  <i class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
  Recherche en cours...
</div>
<div  class="modal-body col-md-10 offset-md-1" [hidden]="isLoading">

  <div class="row form-row">
    <label class="col-md-8 form-control-label" for="field_eausuperf_source">Nom de l'eau superficielle ou de la source </label>
  </div>
  <div class="row form-group form-row">
    <input type="text" class="col-md-8 form-control" name="eausuperfSource" id="field_eausuperf_source" [(ngModel)]="ouvrage.eausuperfSource"  maxlength="255"/>
  </div>

  <span>Cliquer <a href="https://bdlisa.eaufrance.fr/" target="_blank">ici</a> pour plus d'information (Log BD Lisa).</span>
  <br/>
  <span>Aquifère sélectionné : </span><span [hidden]="isntKnown">{{aquifereSelected.code}} - {{aquifereSelected.nature}} - Niveau {{aquifereSelected.niveau}} - {{aquifereSelected.nom}} <button [hidden]="isntKnown" (click)="changeUnknown($event)" class="btn btn-danger btn-sm"><span class="fas fa-times"></span></button></span><span [hidden]="!isntKnown">Aucun (ne sait pas)</span>

  <div class="table-responsive table-aquifere">
    <p-table [value]="aquiferes" class="table table-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col">Code</th>
          <th scope="col">Nature</th>
          <th scope="col">Nom</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-aquifere>
        <tr>
          <td>
            <span *ngIf="aquifere.niveau != '1'" class="col-md-1"></span>
            <span *ngIf="aquifere.niveau == '3'" class="col-md-1"></span>
            <input type="radio" class="col-md-1" [ngModelOptions]="{standalone: true}" [(ngModel)]="aquifereSelected" [value]="aquifere" name="aquifere" style="margin-top: 6.4px;" *ngIf="aquifere.niveau != '1'" (click)="changeAquifere(aquifere)" />
            <label class="col-md-7">{{aquifere.code}}</label>
            </td>
          <td>{{aquifere.nature}}</td>
          <td>{{aquifere.nom}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptyMessage">
        <tr>
          <td colspan="3">
            Aucun aquifère.
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<!--<div class="modal-footer">
  <div class="col-md-6">
    <input type="button" name="previous" class="previous action-button-previous" value="Annuler" (click)="cancel()"/>
    <input type="button" name="next" class="next action-button" value="Valider" (click)="validate()" [disabled]="validateAquifere()" />
  </div>
</div>-->