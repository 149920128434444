import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Utilisateur } from './utilisateur.model';
import { UtilisateurService } from './utilisateur.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DuplosEventManager } from 'src/app/shared/event/event.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { ITEMS_PER_PAGE } from 'src/app/shared/constants/pagination.constants';
import { LexProfilUtilisateur } from '../lex-profil-utilisateur/lex-profil-utilisateur.model';
import { LexProfilUtilisateurService } from '../lex-profil-utilisateur/lex-profil-utilisateur.service';
import * as _ from 'lodash';
import { RegionService } from '../region/region.service';
import { DepartementService } from '../departement/departement.service';
import { DateUtils } from 'src/app/shared/model/date-utils';

@Component({
    selector: 'dup-communal-utilisateur',
    templateUrl: './communal-utilisateur.component.html',
    styleUrls: [
        'utilisateur.css'
      ],
})
export class CommunalUtilisateurComponent implements OnInit, OnDestroy {
    utilisateurs: Utilisateur[];
    currentAccount: any;
    eventSubscriber: Subscription;
    lexprofilutilisateurs: LexProfilUtilisateur[];

    isLoading: boolean;
    

    itemsPerPage: any;
    totalUtilisateurs:number;
    page:any;
    sortColumn:string;
    sortOrder:string;

    constructor(
        private utilisateurService: UtilisateurService,
        private jhiAlertService: AlertService,
        private eventManager: DuplosEventManager,
        private principal: Principal,
        private lexprofilutilisateurService: LexProfilUtilisateurService,
        private regionService: RegionService,
        private departementService: DepartementService,
        private dateUtils: DateUtils
    ) {
        this.isLoading = false;
        this.page = 0;
        this.itemsPerPage = ITEMS_PER_PAGE;
        this.sortColumn = "id";
        this.sortOrder = "DESC";
        
    }


    loadUtilisateursLazy(event) {
        this.page = event.first/this.itemsPerPage;
        if(event.sortField)
            this.sortColumn = event.sortField;
        if(event.sortOrder == 1)
        {
            this.sortOrder = "ASC";
        }
        else {
            this.sortOrder = "DESC";
        }
        this.loadAll();
    }

    loadAll() {
        this.utilisateurService.queryForValidationCommunal({
            page: this.page,
            size: this.itemsPerPage,
            sort: this.sortColumn.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`) + "," + this.sortOrder,
          }).subscribe(
            (res) => {
                this.utilisateurs = res.utilisateurs;
                this.totalUtilisateurs = res.maxUtilisateur;
            },
            (res) => this.onError(res)
        );
    }
    ngOnInit() {
        this.loadAll();
        this.principal.identity().then((account) => {
            this.currentAccount = account;
        });
        this.registerChangeInUtilisateurs();
    }

    ngOnDestroy() {
        this.eventManager.destroy(this.eventSubscriber);
    }

    trackId(index: number, item: Utilisateur) {
        return item.id;
    }
    registerChangeInUtilisateurs() {
        this.eventSubscriber = this.eventManager.subscribe('utilisateurListModification', (response) => this.loadAll());
    }


    private onError(error) {
        this.jhiAlertService.error(error.message, null, null);
    }
}
