<div class="modal-header">
    <h2 class="modal-title">Modèle type d'attestation sur l'honneur</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-delete-popup">
    <p>ATTESTATION SUR L’HONNEUR</p>
    <p>Je soussigné(e)  ......</p>
    <p>demeurant </p>
    <p>adresse   ...</p>
    <p>code postal, commune  ...   ... </p>
    <p>Atteste sur l’honneur que je ne dispose pas de document</p>
    <p>contenant la description géologique et la description technique décrivant le forage situé </p>
    <p>Adresse ...</p>
    <p>code postal, commune  ...   ...</p>
    <p></p> 
    <p>Fait à  ...  </p>  
    <p>Le ...   </p>
    <p>Signature ...</p>
</div>
<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="validate" class="btn btn-danger btn-md float-right" value="Fermer" (click)="cancel()"/>
    </div>
</div>