<form name="editForm" role="form" (ngSubmit)="save()" #editForm="ngForm" >
    <div class="modal-header">
        <h4 class="modal-title" id="myUtilisateurLabel">Modifier un Utilisateur</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true"
                (click)="clear()">&times;</button>
    </div>
    <div class="modal-body col-md-10 offset-md-1">
        <jhi-alert-error></jhi-alert-error>
        <div class="row form-group">
            <label class="col-md-5 form-control-label" for="email">Identifiant</label>
            <input type="email" class="col-md-6 form-control" id="email" name="email" placeholder="Votre email"
                    [(ngModel)]="utilisateur.email" minlength="5" maxlength="100" email disabled pattern="^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$">
        </div>
        <div class="row form-group">
            <label class="col-md-5">Profil Utilisateur <span class="asterisk-red">*</span></label>
            <span *ngFor="let lexProfilUtilisateurOption of lexprofilutilisateurs" class="col-md-6 offset-md-5" >
                <input type="radio" class="col-md-2" name="profilUtilisateur" [value]="lexProfilUtilisateurOption.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="utilisateur.profilUtilisateurId" (click)="changeProfil(lexProfilUtilisateurOption)" />
                <label class="col-md-10">{{lexProfilUtilisateurOption.libelle}}</label>
            </span>
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS  || isState)">
            <label class="col-md-5">Statut <span class="asterisk-red">*</span></label>
            <span *ngFor="let lexStatutJuridique of lexstatutjuridiques" class="col-md-2">
                <input type="radio" class="col-md-2" name="field_statut" id="field_statut_{{lexStatutJuridique.code}}" [value]="lexStatutJuridique.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="utilisateur.statutJuridiqueId"
                    (change)="changeStatut(lexStatutJuridique)" [disabled]="isDDT" />
                <label class="col-md-10" for="field_statut_{{lexStatutJuridique.code}}">{{lexStatutJuridique.libelle}}</label>
            </span>
        </div>

        <div class="row form-group">
            <label class="col-md-5 form-control-label" for="firstName" *ngIf="!isState">Prénom</label>
            <input type="text" class="col-md-6 form-control" id="firstName" name="firstName" placeholder="Votre prénom"
                [(ngModel)]="contact.prenom" [disabled]="isDDT" minlength=1 maxlength=50 *ngIf="!isState">
        </div>
        <div class="row form-group">
            <label class="col-md-5 form-control-label" for="lastName">Nom</label>
            <input type="text" class="col-md-6 form-control" id="lastName" name="lastName" placeholder="Votre nom" [disabled]="isDDT"
                    [(ngModel)]="contact.nom" minlength=1 maxlength=50>
        </div>
        <div class="row form-group">
            <label class="col-md-5 form-control-label" for="emailContact">Courriel de contact</label>
            <input type="email" class="col-md-6 form-control" id="emailContact" name="emailContact" placeholder="Courriel de contact" [disabled]="isDDT"
                    [(ngModel)]="contact.emailContact" minlength="5" maxlength="100" email pattern="^[a-zA-Z0-9.!#$&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$">
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_tel1">Téléphone fixe</label>
            <input type="text" class="col-md-6 form-control" name="tel1" id="field_tel1" [disabled]="isDDT"
                [(ngModel)]="contact.tel1" minlength="10" maxlength="10"/>
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_tel2">Téléphone portable</label>
            <input type="text" class="col-md-6 form-control" name="tel2" id="field_tel2" [disabled]="isDDT"
                [(ngModel)]="contact.tel2" minlength="10" maxlength="10"/>
        </div>
        <div class="form-group" [hidden]="!isPro">
            <h4 class="col-md-12">Coordonnées de l'établissement</h4>
        </div>
        <div class="row form-group" *ngIf="isPro">
            <label class="col-md-5 form-control-label" for="field_siret">Siret <span class="asterisk-red">*</span></label>
            <input type="text" class="col-md-4 form-control" name="siret" id="field_siret" [disabled]="isDDT"
                [(ngModel)]="utilisateur.siretEtablissement" maxlength="14"/>
            <input type="button" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" [disabled]="isDDT" (click)="findBySiret()" style="margin-top:-5px"/>
        </div>
        <div class="row form-group" *ngIf="isPro">
            <label class="col-md-5 form-control-label" for="field_rs">Raison sociale <span class="asterisk-red">*</span></label>
            <input type="text" class="col-md-6 form-control" name="rs" id="field_rs" [disabled]="isDDT"
                [(ngModel)]="utilisateur.raisonSocialeEtab" maxlength="200"/>
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_pays">Pays</label>
            <span class="col-md-2">
                <input type="radio" class="col-md-2" name="pays" id="paysFrance" [(ngModel)]="isFrance" [disabled]="isDDT"
                    [value]="true" (click)="changeToFrance()"/>
                <label for="paysFrance" class="col-md-10">France</label>
            </span>
            <span class="col-md-4">
                <input type="radio" class="col-md-2" name="pays" id="paysAutre" [(ngModel)]="isFrance" [disabled]="isDDT"
                    [value]="false"/>
                <label for="paysAutre" class="col-md-10">Autre (préciser)</label>
            </span>
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS || isFrance)">
            <input type="text" class="col-md-6 offset-md-5 form-control" name="paysText" id="field_pays"
                [(ngModel)]="adresse.pays" maxlength="50" [disabled]="isFrance || isDDT"/>
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_nomVoie">Adresse</label>
            <input type="text" class="col-md-6 form-control" name="nomVoie" id="field_nomVoie" [disabled]="isDDT"
                [(ngModel)]="adresse.nomVoie" maxlength="100" />
        </div>
        <div class="row form-group" *ngIf="!(isAdmin || isBSS)">
            <label *ngIf="isFrance" class="col-md-5 form-control-label" for="field_codePostal">Code postal</label>
            <label *ngIf="!isFrance" class="col-md-5 form-control-label" for="field_codePostal">Zip code</label>
            <input type="text" class="col-md-4 form-control" name="codePostal" id="field_codePostal" [disabled]="isDDT"
                [(ngModel)]="adresse.codePostal" maxlength="5" />
            <input type="button" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" (click)="findCity($event)" [disabled]="!adresse.codePostal || adresse.codePostal.length!=5 || isDDT" style="margin-top:-5px"/>
        </div>

        <div class="row form-group" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_region">Région</label>
            <ng-select class="col-md-6 dropdown-no-padding" id="field_region" name="region" [(ngModel)]="region" [items]="regions" bindLabel="nomReg" [disabled]="isDDT" (change)="onSelectRegion($event)">
            </ng-select>
        </div>

        <div class="row form-group" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5 form-control-label" for="field_departement" [hidden]="!region">Département</label>
            <ng-select class="col-md-6 dropdown-no-padding" id="field_departement" name="departement" [(ngModel)]="departement" [items]="departements" [disabled]="isDDT" bindLabel="nomDep" (change)="onSelectDepartement($event)" [hidden]="!region">
            </ng-select>
        </div>
        <div class="row form-group" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS)">
            <label class="col-md-5" for="field_commune" [hidden]="!departement">Commune</label>
            <ng-select class="col-md-6 dropdown-no-padding" id="field_commune" name="commune" [(ngModel)]="commune" [items]="communes" [disabled]="isDDT" bindLabel="nomCom" [loading]="communeLoading" (change)="onSelectCommune($event)" [hidden]="!departement">
            </ng-select>
        </div>

        <div class="row form-group" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
            <label class="col-md-5" for="field_complement">Complément</label>
            <input type="text" class="col-md-6" name="complement" id="field_complement" [disabled]="isDDT"
                [(ngModel)]="adresse.complement" maxlength="50"/>
        </div>

        <div class="row form-group" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
            <label class="col-md-5" for="field_lieuDit">Lieu-dit</label>
            <input type="text" class="col-md-6" name="lieuDit" id="field_lieuDit" [disabled]="isDDT"
                [(ngModel)]="adresse.lieuDit" maxlength="50"/>
        </div>

        <div class="row form-group" *ngIf="!(isAdmin || isBSS)" [hidden]="isFrance">
            <label class="col-md-5 form-control-label" for="field_lieuDit">Ville</label>
            <input type="text" class="col-md-6 form-control" name="ville" id="field_Ville" [disabled]="isDDT"
                [(ngModel)]="adresse.lieuDit" maxlength="50"/>
        </div>

        <div class="row form-group" [hidden]="!isFrance" *ngIf="isPro">
            <label class="col-md-5 form-control-label" for="field_bp">Bp</label>
            <input type="text" class="col-md-6 form-control" name="bp" id="field_bp" [disabled]="isDDT"
                [(ngModel)]="adresse.bp" maxlength="50"/>
        </div>

        <div class="row form-group" [hidden]="!isFrance" *ngIf="isPro">
            <label class="col-md-5 form-control-label" for="field_cedex">Cedex</label>
            <input type="text" class="col-md-6 form-control" name="cedex" id="field_cedex" [disabled]="isDDT"
                [(ngModel)]="adresse.cedex" maxlength="50"/>
        </div>
        <div class="row form-group" *ngIf="isState">
            <label class="col-md-5 form-control-label" for="field_zone">Zone</label>
            <div class="col-md-6" *ngIf="isRegion">
                <p-multiSelect defaultLabel="Sélectionner" filterPlaceHolder="Sélectionner" [options]="zonesRegion" [disabled]="isDDT" [(ngModel)]="selectedZonesRegion" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
            <div class="col-md-6" *ngIf="!isRegion">
                <p-multiSelect defaultLabel="Sélectionner" filterPlaceHolder="Sélectionner" [options]="zonesDepartement" [disabled]="isDDT" [(ngModel)]="selectedZonesDepartement" [ngModelOptions]="{standalone: true}"></p-multiSelect>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
            <span class="fa fa-ban"></span>&nbsp;<span>Annuler</span>
        </button>
        <button type="submit" [disabled]="editForm.form.invalid || isSaving" class="btn btn-primary">
            <span class="fa fa-save"></span>&nbsp;<span>Sauvegarder</span>
        </button>
    </div>
</form>
