<div class="col-md-12">

    <h2><label>Informations complémentaires</label></h2>

    <label style="font-size: 20px;"> Je dispose de documents décrivant les forages existants (fichier .pdf, max 10Mo)<label style="color:red">*</label> </label>

    <div style="margin: 20px;">

        <div class="alert alert-danger" [hidden]="!errorFileSize" >La taille de la pièce jointe dépasse la limite maximale (10 Mo)</div>
        <div class="alert alert-danger" [hidden]="!errorFileName" >Nom de fichier trop long (80 caractères, espaces compris maximum). Renommez votre fichier avant de le déposer.</div>
        

        <label class="custom-checkbox">
            <input type="radio" id="addFileCheckbox" [(ngModel)]="radiofileToUpload" [value]="1" (change)="computeValidate()">
            &nbsp;Oui
        </label>

    </div>

    <div style="margin: 20px;">
        <label class="custom-checkbox">
            <input type="radio" id="noFileCheckbox" [(ngModel)]="radiofileToUpload"  [value]="2" (change)="computeValidate()">
            &nbsp;Non et je joins une attestation sur l'honneur (<span class="lien-modele-type" (click)="displayModeleType()">modèle type</span>)
        </label>
    </div>

    <p-fileUpload #fileUploader id="fileuploadfinalisationbtn" mode="basic" styleClass="btn btn-md height100" [auto]="true" 
             chooseIcon="pi-file" uploadIcon="pi-check" (cancel)="onCancelFileUpload()" maxFileSize="10485760" chooseLabel="Joindre mon fichier"
             name="myFiles[]" customUpload="true" (uploadHandler)="myUploader($event, fileUploader)"  
            (onSelect)="onSelectUpload($event,fileUploader)" accept=".pdf">
    </p-fileUpload>

    <div [hidden]="!fileData" class="col-md-6" style="margin-top: 15px;">
        <ul class="list-group list-group-horizontal">
            <li class="list-group-item"><i class="fa fa-paperclip"></i></li>
            <li class="list-group-item">{{ fileData?.get("nomFichier") }}</li>
            <li class="list-group-item">{{ fileInfo }}</li>
          </ul>
    </div>

    <br/>

    <label style="font-size: 20px;"> Commentaire libre sur la déclaration (max. 1000 caractères) <label [hidden]="!askNoFile" style="color:red">*</label> :</label>
    <textarea id="commentaire" name="commentaire" (input)="computeValidate()" [(ngModel)]="declaration.commentaire" rows="3" [required]="askNoFile" style="width:70%; margin-left: 50px; border-radius: 5px;" ></textarea>

  </div>

      
  
