import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {SelectItem} from 'primeng/api';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import * as $ from 'jquery';
import * as _ from 'lodash';

import { Utilisateur } from './utilisateur.model';
import { UtilisateurPopupService } from './utilisateur-popup.service';
import { UtilisateurService } from './utilisateur.service';
import { Contact } from '../contact/contact.model';
import { ContactService } from '../contact/contact.service';
import { Adresse } from '../adresse/adresse.model';
import { AdresseService } from '../adresse/adresse.service';
import { DepartementService } from '../departement/departement.service';
import { RegionService } from '../region/region.service';
import { CommuneService } from '../commune/commune.service';
import { Region } from '../region/region.model';
import { Departement } from '../departement/departement.model';
import { Commune } from '../commune/commune.model';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DuplosEventManager } from 'src/app/shared/event/event.service';
import { LexStatutJuridique } from '../lex-statut-juridique/lex-statut-juridique.model';
import { LexProfilUtilisateur } from '../lex-profil-utilisateur/lex-profil-utilisateur.model';
import { LexStatutJuridiqueService } from '../lex-statut-juridique/lex-statut-juridique.service';
import { LexProfilUtilisateurService } from '../lex-profil-utilisateur/lex-profil-utilisateur.service';
import { ApiService } from 'src/app/shared/service/api.service';
import { ActeurService } from '../acteur/acteur.service';
import { Principal } from 'src/app/shared/auth/principal.service';


@Component({
    selector: 'dup-utilisateur-dialog',
    templateUrl: './utilisateur-dialog.component.html',
    styleUrls: [
        'utilisateur.css'
    ]
})
export class UtilisateurDialogComponent implements OnInit, AfterViewInit {


    @Input() utilisateur: Utilisateur;

    isSaving: boolean;

    contact: Contact;

    adresse: Adresse;

    region: Region;
    departement: Departement;
    commune: Commune;

    regions: Region[];
    departements: Departement[];
    communes: Commune[];

    lexstatutjuridiques: LexStatutJuridique[];

    lexprofilutilisateurs: LexProfilUtilisateur[];

    zonesRegion: SelectItem[];
    zonesDepartement: SelectItem[];
    selectedZonesRegion: any[];
    selectedZonesDepartement: any[];

    utilisateurs: Utilisateur[];
    dateCreationDp: any;
    dateModificationDp: any;

    // utilisateur professionel
    isPro: Boolean = false;
    isAdmin: Boolean = false;
    isDDT: boolean = true;
    isBSS: Boolean = false;
    isState: Boolean = false;
    isFrance:Boolean = true;
    isRegion:Boolean = true;

    communeLoading: Boolean = false;

    constructor(
        public activeModal: NgbActiveModal,
        private jhiAlertService: AlertService,
        private utilisateurService: UtilisateurService,
        private contactService: ContactService,
        private adresseService: AdresseService,
        private regionService: RegionService,
        private departementService: DepartementService,
        private communeService: CommuneService,
        private ouvrageDeclaration: ApiService,
        private lexStatutJuridiqueService: LexStatutJuridiqueService,
        private lexProfilUtilisateurService: LexProfilUtilisateurService,
        private eventManager: DuplosEventManager,
        private acteurService: ActeurService,
        private principal: Principal,
    ) {
        this.contact = new Contact();
        this.adresse = new Adresse();
        this.selectedZonesRegion = [];
        this.selectedZonesDepartement = [];
        this.zonesRegion = [];
        this.zonesDepartement = [];
    }

    ngOnInit() {
        this.isSaving = false;
        this.zonesRegion = [];
        this.zonesDepartement = [];

        this.principal.identity().then((account) => {
            if(account) {
                this.isDDT = account.profilUtilisateurId == 4400;
                if(this.lexprofilutilisateurs){
                    //seulement Declarant et Communal pour les DDT
                    this.lexprofilutilisateurs = this.lexprofilutilisateurs.filter(e=> [4200, 4450].includes(e.id));
                }
            }
        });


        this.utilisateurService.isAdmin(this.utilisateur.id).subscribe(res => this.isAdmin = res);
        this.utilisateurService.isBSS(this.utilisateur.id).subscribe(res => this.isBSS = res);
        this.utilisateurService.isState(this.utilisateur.id).subscribe(res => {
            this.isState = res;
            if(this.isState) {
                this.utilisateurService.isRegion(this.utilisateur.id).subscribe(res => this.isRegion = res);
            }
        });

        if(this.utilisateur.statutJuridiqueId) {
            this.utilisateurService.isPro(this.utilisateur.id).subscribe(res => this.isPro = res);
        }

        if (!this.utilisateur.contactId) {
            this.contact = new Contact();
        } else {
            this.contactService
                .find(this.utilisateur.contactId)
                .subscribe((subRes: Contact) => {
                    this.contact = subRes;
                }, (subRes) => this.onError(subRes));
        }

        if (!this.utilisateur.adresseId) {
            this.adresse = new Adresse();
            this.adresse.pays = "France";
        } else {
            this.adresseService
                .find(this.utilisateur.adresseId)
                .subscribe((subRes: Adresse) => {
                    this.adresse = subRes;
                    if(!this.adresse.pays) {
                        this.changeToFrance();
                    }
                    if(this.adresse.pays.toLowerCase() != "france") {
                        this.isFrance = false;
                    } else {
                        if(this.adresse.communeInseeCom) {
                            this.communeService.find(this.adresse.communeInseeCom).subscribe((commune) => {
                                this.commune = commune
                                this.regions.forEach((r) => {
                                    if(r.inseeReg == this.commune.inseeReg) {
                                        this.region = r;
                                        this.onSelectRegion(null);
                                    }
                                });
                            });
                        }
                    }
                }, (subRes) => this.onError(subRes));
        }
        this.lexStatutJuridiqueService.query()
            .subscribe((res) => {
                this.lexstatutjuridiques = res;
            }, (res) => this.onError(res));
        this.lexProfilUtilisateurService.query()
            .subscribe((res) => {
                this.lexprofilutilisateurs = res;
                if(this.isDDT){
                    //seulement Declarant et Communal pour les DDT
                    this.lexprofilutilisateurs = res.filter(e=> [4200, 4450].includes(e.id));
                }
             }, (res) => this.onError(res));
        this.regionService.query().subscribe((reg) => {
            this.regions = reg;
            if(this.commune) {
                this.regions.forEach((r) => {
                    if(r.inseeReg == this.commune.inseeReg) {
                        this.region = r;
                        this.onSelectRegion(null);
                    }
                });
            }
            reg.forEach(region => {
                let zone = {label: region.nomReg, value: {id: region.inseeReg, type: "region"}};
                this.zonesRegion = [...this.zonesRegion, zone];
            });
            this.departementService.query().subscribe((dep) => {
                dep.forEach(departement => {
                    let zone = {label: departement.nomDep, value: {id: departement.inseeDep, type: "departement"}};
                    this.zonesDepartement = [...this.zonesDepartement, zone];
                });
                this.utilisateurService.getZones(this.utilisateur.id).subscribe(zones => {
                    zones.forEach(zone => {
                        if(zone.regionInseeReg) {
                            this.selectedZonesRegion = [...this.selectedZonesRegion, _.find(this.zonesRegion, ["label", zone.regionNomReg]).value];
                        }
                        if(zone.departementInseeDep) {
                            this.selectedZonesDepartement = [...this.selectedZonesDepartement, _.find(this.zonesDepartement, ["label", zone.departementNomDep]).value];
                        }
                    });
                });
            });
        });
    }

    ngAfterViewInit(): void {
        if(!(this.isAdmin || this.isBSS)) {
            $('#field_pays').attr('required', '');
            $('#field_region').attr('required', '');
            $('#field_departement').attr('required', '');
            $('#field_commune').attr('required', '');
        }

        if(this.isState) {
            $('#field_zone').attr('required', '');
        } else {
            $('#monsieur').attr('required', '');
        }

        if(this.isPro) {
            $('#field_siret').attr('required', '');
            $('#field_rs').attr('required', '');
        }
    }

    public onSelectRegion(event) {
        if(this.region) {
            this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
                this.departements = dep;
                if(event == null) {
                    this.departements.forEach((d) => {
                        if(d.inseeDep == this.commune.inseeDep) {
                            this.departement = d;
                            this.onSelectDepartement(null);
                        }
                    });
                }
            });
        }
    }

    public onSelectDepartement(event) {
        if(this.departement) {
            this.communeLoading = true;
            this.communeService.findByDepartement(this.departement.inseeDep).subscribe((com) => {
                this.communes = com;
                this.communeLoading = false;
                /*if(event == null) {
                    this.communes.forEach((c) => {
                        if(c.inseeCom == this.adresse.communeInseeCom) {
                            this.commune = c;
                        }
                    });
                }*/
            });
        }
    }

    public onSelectCommune(event) {
        if(this.commune) {
            this.adresse.communeInseeCom = this.commune.inseeCom;
        }
    }

    changeProfil(profilUtilisateur:LexProfilUtilisateur) {
        this.utilisateur.profilUtilisateurId = profilUtilisateur.id;
        this.utilisateur.profilUtilisateurLibelle = profilUtilisateur.libelle;

        this.isAdmin = profilUtilisateur.code === '0';
        this.isBSS = profilUtilisateur.code === '1';
        this.isState = profilUtilisateur.code === '20' || profilUtilisateur.code === '21' || profilUtilisateur.code === '22' || profilUtilisateur.code === '23';
        this.isRegion = profilUtilisateur.code === '20' || profilUtilisateur.code === '21';

        if(this.isAdmin || this.isBSS || this.isState) {
            this.utilisateur.statutJuridiqueId = _.find(this.lexstatutjuridiques, ['mnemo','PART']).id;
            $('#field_pays').removeAttr('required');
            $('#field_region').removeAttr('required');
            $('#field_departement').removeAttr('required');
            $('#field_commune').removeAttr('required');
            $('#field_siret').removeAttr('required');
            $('#field_rs').removeAttr('required');
        }

        if(!(this.isAdmin || this.isBSS)) {
            $('#field_pays').attr('required', '');
            $('#field_region').attr('required', '');
            $('#field_departement').attr('required', '');
            $('#field_commune').attr('required', '');
        }

        if(this.isState) {
            $('field_zone').attr('required', '');
            $('#monsieur').removeAttr('required', '');
        } else {
            $('field_zone').removeAttr('required');
            $('#monsieur').attr('required', '');
        }
    }

    changeStatut(statutJuridique:LexStatutJuridique) {
        this.utilisateur.statutJuridiqueId = statutJuridique.id;
        this.utilisateur.statutJuridiqueLibelle = statutJuridique.libelle;
        this.isPro = statutJuridique.mnemo === 'PRO';
        if(this.isPro) {
            $('#field_siret').attr('required', '');
            $('#field_rs').attr('required', '');
        }
    }

    findBySiret() {
        this.acteurService.getActeurBySiret(this.utilisateur.siretEtablissement).subscribe((res:any) => {
          let result = res.results[0];
          const lexstatutjuridique = _.find(this.lexstatutjuridiques,['code','2']);
          this.isPro = lexstatutjuridique.mnemo === 'PRO';
          this.utilisateur.statutJuridiqueId = lexstatutjuridique.id;
          this.utilisateur.raisonSocialeEtab = result.nom_raison_sociale;

          this.adresse.nomVoie = result.siege.numero_voie + " " + result.siege.type_voie + " " + result.siege.libelle_voie;
          this.adresse.codePostal = result.siege.code_postal;
          this.adresse.pays = 'France';
          this.adresse.communeInseeCom = result.siege.commune;
          this.adresse.cedex = result.siege.cedex;
          this.adresse.complement = result.siege.complement_adresse;
          this.communeService.find(this.adresse.communeInseeCom).subscribe((commune) => {
              this.adresse.commune = commune;
              this.commune = commune;
              this.region = this.regions.find(r => r.inseeReg == commune.inseeReg );
              this.onSelectRegion(null);  
          });
        });
    }

    findCity(event) {
        this.communeService.findByCp(this.adresse.codePostal).subscribe((communes) =>
        {
          this.communes = communes;
          if(this.communes.length == 1)
          {
            this.commune = this.communes[0];
            this.adresse.commune = this.commune;
            this.adresse.communeInseeCom = this.commune.inseeCom;
          }

          setTimeout(() => {
            this.regions.forEach((r) => {
              if(r.inseeReg == this.communes[0].inseeReg) {
                  this.region = r;
                  this.departementService.findByRegion(this.region.inseeReg).subscribe((dep) => {
                      this.departements = dep;
                      this.departements.forEach((d) => {
                        if(d.inseeDep == this.communes[0].inseeDep) {
                            this.departement = d;
                        }
                    });
                  });
              }
            });
          }, 500);
        });
    }

    clear() {
        this.activeModal.dismiss('cancel');
    }

    save() {
        this.isSaving = true;
        this.saveAdress();
    }

    saveAdress() {
        if(this.adresse.id != null) {
            this.adresseService.update(this.adresse).subscribe((updatedAdresse: Adresse) => {
                this.utilisateur.adresseId = updatedAdresse.id;
                this.saveContact();
            },
            (res:any) => this.onSaveError());
        } else {
            this.adresseService.create(this.adresse).subscribe((savedAdresse: Adresse) => {
                this.utilisateur.adresseId = savedAdresse.id;
                this.saveContact();
            }
            , (res: any) => this.onSaveError());
        }
    }

    saveContact() {
        if(this.contact.id != null) {
            this.contactService.update(this.contact).subscribe((updatedContact: Contact) => {
                this.utilisateur.contactId = updatedContact.id;
                this.saveUtilisateur();
            }
            , (res: any) => this.onSaveError());
        } else {
            this.contactService.create(this.contact).subscribe((savedContact: Contact) => {
                this.utilisateur.contactId = savedContact.id;
                this.saveUtilisateur();
            }
            , (res: any) => this.onSaveError());
        }
    }

    saveUtilisateur() {
        if (this.utilisateur.id !== undefined) {
            this.subscribeToSaveResponse(
                this.utilisateurService.update(this.utilisateur));
        } else {
            this.subscribeToSaveResponse(
                this.utilisateurService.create(this.utilisateur));
        }
    }

    private subscribeToSaveResponse(result: Observable<Utilisateur>) {
        result.subscribe((res: Utilisateur) =>
            {
                if(!_.isEmpty(this.selectedZonesRegion)) {
                    let regionIds = _.map(_.filter(this.selectedZonesRegion, ["type", "region"]), function(s) {return s.id});
                    this.utilisateurService.updateZones(res.id, regionIds, []).subscribe((zone) => {this.onSaveSuccess(res);});
                } else if(!_.isEmpty(this.selectedZonesDepartement)) {
                    let departementIds = _.map(_.filter(this.selectedZonesDepartement, ["type", "departement"]), function(s) {return s.id});
                    this.utilisateurService.updateZones(res.id, [], departementIds).subscribe((zone) => {this.onSaveSuccess(res);});
                } else this.onSaveSuccess(res);
            }, (res) => this.onSaveError());
    }

    private onSaveSuccess(result: Utilisateur) {
        this.eventManager.broadcast({ name: 'utilisateurListModification', content: 'OK'});
        this.isSaving = false;
        this.activeModal.dismiss(result);
    }

    private onSaveError() {
        this.isSaving = false;
    }

    private onError(error: any) {
        this.jhiAlertService.error(error.message, null, null);
    }

    trackContactById(index: number, item: Contact) {
        return item.id;
    }

    trackAdresseById(index: number, item: Adresse) {
        return item.id;
    }

    trackLexStatutJuridiqueById(index: number, item: LexStatutJuridique) {
        return item.id;
    }

    trackLexProfilUtilisateurById(index: number, item: LexProfilUtilisateur) {
        return item.id;
    }

    trackUtilisateurById(index: number, item: Utilisateur) {
        return item.id;
    }

    changeToFrance() {
        this.adresse.pays = "France";
      }
}

@Component({
    selector: 'dup-utilisateur-popup',
    template: ''
})
export class UtilisateurPopupComponent implements OnInit, OnDestroy {

    routeSub: any;

    constructor(
        private route: ActivatedRoute,
        private utilisateurPopupService: UtilisateurPopupService
    ) {}

    ngOnInit() {
        this.routeSub = this.route.params.subscribe((params) => {
            if ( params['id'] ) {
                this.utilisateurPopupService
                    .open(UtilisateurDialogComponent as Component, params['id']);
            } else {
                this.utilisateurPopupService
                    .open(UtilisateurDialogComponent as Component);
            }
        });
    }

    ngOnDestroy() {
        this.routeSub.unsubscribe();
    }
}
