<div class="modal-header" *ngIf="declarationType == 1800">
    <h2 class="modal-title">Confirmation de déclaration (régularisation d’ouvrage existant)</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-header" *ngIf="declarationType != 1800">
    <h2 class="modal-title">Finalisation de déclaration</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="saving" [hidden]="!isLoading">
    <p-progressBar [value]="value"></p-progressBar>
    Enregistrement dans la Banque du Sous-Sol (BSS) en cours...
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-popup" *ngIf="!ready && declarationType != 1800">
    <p [hidden]="!showErrorFileName" style="text-align: center; color: red ; font-weight: bold;" > Nom de fichier trop long (80 caractères, espaces compris maximum). Renommez votre fichier avant de le déposer. </p>
    <p [hidden]="!showErrorFileSize" style="text-align: center; color: red ; font-weight: bold;" > La taille de la pièce jointe dépasse la limite maximale (10 Mo). </p>
    <p style="text-align: center">Pour finaliser votre déclaration, vous devez déposer un rapport de fin de forage.</p>
    <p style="text-align: center">2 possibilités vous sont proposées</p>
    <div class="row">
        <div class="col-5">
            <button type="button" name="dialog" class="btn btn-primary btn-md custom-button-dark-blue" 
               value="dialog" (click)="openDialog()">Créer votre rapport avec l'outil <br>
                <strong> dialog </strong> <br> mis à votre disposition par le BRGM</button>
        </div>
        <div style="text-align: center; display: flex; justify-content: center; align-items: center;" class="col-2">
            <span>ou</span>
        </div>
        <div class="col-5">
            <p-fileUpload #fileUpload id="fileuploadfinalisationbtn" mode="basic" styleClass="btn btn-md custom-button-dark-blue height100" [auto]="true" 
            chooseLabel="Déposer le document PDF de votre choix" maxFileSize="10000000"  chooseIcon="" uploadIcon="" style="height: 100%; display: block;" class="custom-button-dark-blue"
               name="myFiles[]" customUpload="true" (uploadHandler)="myUploader($event)" (onSelect)="onSelectUpload($event,fileUpload)" accept=".pdf"></p-fileUpload>
        </div>
    </div>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-ready" *ngIf="ready">
    <p>Vous vous apprêtez à finaliser votre déclaration n°{{declarationId}} avec le dépôt d’un rapport de fin de forage.</p>
    <p>Cette opération sera bancarisée en BSS et notifiée à la DREAL et est irréversible. Voulez-vous continuer ?</p>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-ready" *ngIf="declarationType == 1800 && !isFordom">
    <p>En cliquant sur Valider, la déclaration est notifiée à la D(R)EAL, ainsi qu’aux services de l’État qui en auront fait la demande. Vous recevrez ainsi que les différents acteurs de la déclaration un récépissé de votre déclaration par courriel. Cette déclaration ne vaut pas autorisation de réaliser l'ouvrage au titre des autres réglementations qui s'appliquent à votre projet. <strong>Une fois transmise, la déclaration ne peut plus être modifiée.</strong> </p>
    <p><strong>Cette transmission entraine automatiquement la finalisation de la procédure, ce qui vous permettra d'obtenir un code BSS pour votre (vos) ouvrage(s) réalisé(s).</strong></p>
    <p>Voulez-vous continuer ?</p>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-final-ready" *ngIf="declarationType == 1800 && isFordom">
    <p>Cette déclaration va vous permettre de générer le Cerfa N°13837*02 relatif à la déclaration d’ouvrage, prélèvements, puits et forages à usage domestique. <strong>Dans le cas où l'(les) ouvrage(s) ferai(en)t plus de 10 m de profondeur, une déclaration au titre de l'article L.411-1 du Code Minier est automatiquement associée</strong>, et un récépissé est envoyé par courriel. </p>
    <p>Dans le cas d'un usage pour la consommation humaine unifamiliale, nous rappelons qu'une analyse de l’eau de type P1, à l’exception du chlore, définie dans l’arrêté du 11 janvier 2007 (relatif au programme de prélèvements et d’analyses du contrôle sanitaire pour les eaux fournies par un réseau de distribution) doit être réalisée et jointe à la déclaration ; pour les cas de consommation autre qu'unifamilial, une autorisation préfectorale doit être demandée au titre de l’article L.1321-7 du code de la santé publique.</p>
    <p>En cliquant sur Valider, le Cerfa généré sera transmis à la mairie de la commune et à la DDT(M). En cas de déclaration Code minier associée, celle-ci est notifiée à la D(R)EAL, ainsi qu’aux services de l’État qui en auront fait la demande. Vous recevrez ainsi que les différents acteurs de la déclaration un courriel comprenant les mêmes documents.<strong>Une fois transmise, la déclaration ne peut plus être modifiée. Cette transmission entraine automatiquement la finalisation de la procédure, ce qui vous permettra d'obtenir un code BSS pour votre (vos) ouvrage(s) réalisé(s).</strong></p>
    <p>Voulez-vous continuer ?</p>
</div>

<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md custom-button-round" value="Annuler" (click)="cancel()"/>
        <input type="button" name="next" class="btn btn-primary btn-md float-right custom-button-dark-blue" value="Valider" (click)="validate()" [disabled]="(!ready && declarationType != 1800) || isLoading" />
    </div>
</div>