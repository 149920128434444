<div class="col-md-10 offset-md-1">
    <h2>
        <span>Comptes communaux</span>
    </h2>


    <dup-alert-error></dup-alert-error>
    <div class="table-responsive" *ngIf="utilisateurs">
        <p-table class="table table-striped" [resetPageOnSort]="false" [sortOrder]="2" [value]="utilisateurs" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalUtilisateurs" dataKey="id"  (onLazyLoad)="loadUtilisateursLazy($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th scope="col" [pSortableColumn]="'id'" style="width: 5em;"><span>ID</span><p-sortIcon [field]="'id'"></p-sortIcon></th>
                    <th scope="col" [pSortableColumn]="'email'"><span>Identifiant</span><p-sortIcon [field]="'email'"></p-sortIcon></th>
                    <th scope="col" ><span>Email contact</span></th>
                    <th scope="col" [pSortableColumn]="'dateCreation'"><span>Date demande</span><p-sortIcon [field]="'dateDemandeProfil'"></p-sortIcon></th>
                    <th scope="col" [pSortableColumn]="'profilUtilisateurId'"><span>Profil Utilisateur</span><p-sortIcon [field]="'profilUtilisateurLibelle'"></p-sortIcon></th>
                    <th scope="col"></th>
                </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-utilisateur>
                <tr>
                    <td>{{utilisateur.id}}</td>
                    <td>{{utilisateur.email}}</td>
                    <td>{{utilisateur.emailContact}}</td>
                    <td>{{utilisateur.dateDemandeProfil | date:'mediumDate'}}</td>
                    <td>
                        <div *ngIf="utilisateur.profilUtilisateurId">
                            {{utilisateur.profilUtilisateurLibelle}}
                        </div>
                    </td>
                    <td class="text-right">
                        <div class="btn-group flex-btn-group-container"> 
                            <button type="submit"
                                    [routerLink]="['/', { outlets: { popup: 'utilisateur/'+ utilisateur.id} }]"
                                    replaceUrl="true"
                                    queryParamsHandling="merge"
                                    class="btn btn-primary btn-sm">
                                <span class="fa fa-pencil"></span>
                                <span class="d-none d-md-inline">Modifier</span>
                            </button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
    