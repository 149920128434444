import { Component, OnInit, Input } from '@angular/core';
import { Acteur } from 'src/app/entities/acteur/acteur.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dup-acteur-popup',
    templateUrl: './acteur-popup.component.html',
    styleUrls: ['../forms.css']
  })
  export class ActeurPopupComponent implements OnInit {
  
    // acteur en cours
    @Input() acteur: Acteur;

    @Input() acteurRef: Acteur;
  
    @Input() acteurType:String;

    isIdent:Boolean = false;

    constructor(
      private ngbModal: NgbActiveModal
    ) {}
  
    ngOnInit() {
      
    }
  
    validateActeur() {
        let baseActeur = this.acteur.statutJuridiqueId != null && this.acteur.email != null;
          if(this.acteur.adresse) {
            baseActeur = baseActeur && this.acteur.adresse.pays != null;
            if(this.acteur.adresse.pays == "France") {
              baseActeur = baseActeur && this.acteur.adresse.communeInseeCom != null;
            } else {
              baseActeur = baseActeur && this.acteur.adresse.lieuDit != null;
            }
          } else {
            baseActeur = false;
          }
          if(this.acteur.statutJuridiqueId == 3800) {
            baseActeur = baseActeur && this.acteur.siretEtab != null && this.acteur.raisonSocialeEtab != null;
            const regex = new RegExp('^[\\0-9]{14}$', 'g');
            baseActeur = this.acteur.siretEtab.match(regex) != null
          }
          
        return !baseActeur;
    }

    ident() {
      if(this.isIdent) {
        this.acteur = this.acteurRef;
      } else {
        this.acteur = new Acteur();
      }
    }
  
    validate() {  
      this.ngbModal.close(this.acteur);
    }
  
    cancel() {
      this.ngbModal.dismiss();
    }
  
  }