<div class="modal-header">
    <h2 class="modal-title">Localisation</h2>
</div>
<div  class="modal-body col-md-10">
    <div class="form-group">
        <h4 class="col-md-12 fs-title">Coordonnées géographiques (X,Y)</h4>
    </div>
    <div *ngIf="!isCarte">
        <div class="row form-row">
            <span *ngFor="let empOption of lextypelocalisation" class="col-md-5">
                <input type="radio" class="col-md-2" name="emplacement" [value]="empOption.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="ouvrage.typeLocalisationId" />
                <label class="col-md-10">{{empOption.libelle}}</label>
            </span>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label" for="field_srs">Système de référence spatial <span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row">
            <ng-select class="col-md-8 dropdown-no-padding" id="field_srs" name="srs" [(ngModel)]="ouvrage.srsId" (change)="changeSrs($event)">
                <ng-option [value]="lexsrsOption.id" *ngFor="let lexsrsOption of lexsrs">{{lexsrsOption.libelle}}</ng-option>
            </ng-select>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Unité</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.srsId? getUnite(ouvrage.srsId):''}}</label>
        </div>
        <span *ngIf="!coord_ok" style="color: red; font-weight: bold;">Le service DUPLOS est actuellement accessible sur toute la France métropolitaine et DROM</span>
        <div class="row form-row">
            <label class="col-md-5 form-control-label" for="field_x">X<span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row">
            <input type="number" step="0.0000001" class="col-md-8 form-control" name="x" id="field_x" [(ngModel)]="ouvrage.x" (blur)="updateGeom()" />
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label" for="field_y">Y<span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row">
            <input  type="number" step="0.0000001" class="col-md-8 form-control" name="y" id="field_y" [(ngModel)]="ouvrage.y" (blur)="updateGeom()" />
        </div>
        <!--div class="row form-row">
            <input *ngIf="ouvrage.typeSaisieCoorId==2650" type="button" name="previous" class="btn btn-success btn-md col-md-6 offset-md-5" value="Localiser l'adresse de l'ouvrage" (click)="localiser()" [disabled]="ouvrage.x == null || ouvrage.y == null"/>
        </div-->
        <div class="row form-row">
            <label class="col-md-5 form-control-label" for="field_methodexy">Méthode utilisée <span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row">
            <ng-select class="col-md-8 dropdown-no-padding" id="field_methodexy" name="methodexy" [(ngModel)]="ouvrage.modeXYId">
                <ng-option [value]="lexMethodeOption.id" *ngFor="let lexMethodeOption of lexmethodesxy">{{lexMethodeOption.libelle}}</ng-option>
            </ng-select>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Précision</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.modeXYId?getPrecisionXY(ouvrage.modeXYId):''}}</label>
        </div>
        <div class="form-row">
            <h4 class="col-md-5 fs-title" style="display: inline-block;">Altimétrie</h4>
            <input type="button" name="modifyz" [hidden]="boutonAltitude" id="modifyz" class="col-md-8 btn btn-warning btn-md" (click)="switchZSaisie()" value="Modifier l'altitude"/>
            <input type="button" name="modifyz2" [hidden]="!boutonAltitude" id="modifyz2" class="col-md-8 btn btn-primary btn-md" (click)="switchZSaisie()" value="Altitude automatique"/>
        </div>
        <div class="row form-row" *ngIf="!modifyz">
            <label class="col-md-5 form-control-label">Altitude calculée (en m) :</label>
        </div><div class="row form-group form-row" *ngIf="!modifyz">
            <label class="col-md-8 form-control">{{ouvrage.zcalc | number:'0.0-2':'fr'}}</label>
        </div>
        <div class="row form-row" *ngIf="!modifyz && ouvrage.modeZCalcId">
            <label class="col-md-5 form-control-label">Méthode utilisée :</label>
        </div><div class="row form-group form-row" *ngIf="!modifyz && ouvrage.modeZCalcId">
            <label class="col-md-8 form-control">{{ouvrage.modeZCalcId?ouvrage.modeZCalcLibelle:''}}</label>
        </div>
        <div class="row form-row" *ngIf="!modifyz && ouvrage.modeZCalcId">
            <label class="col-md-5 form-control-label">Précision :</label>
        </div><div class="row form-group form-row" *ngIf="!modifyz && ouvrage.modeZCalcId">
            <label class="col-md-8 form-control">{{ouvrage.modeZCalcId?getPrecisionZ(ouvrage.modeZCalcId):''}}</label>
        </div>
        <div class="row form-row" *ngIf="modifyz">
            <label class="col-md-5 form-control-label" for="field_z">Altitude (en m) :<span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row" *ngIf="modifyz">
            <input type="number" step="0.0000001" class="col-md-8 form-control" name="z" id="field_z" [(ngModel)]="ouvrage.zsaisie" />
        </div>
        <div class="row form-row" *ngIf="modifyz">
            <label class="col-md-5 form-control-label" for="field_methodez">Méthode utilisée :<span class="asterisk-red">*</span></label>
        </div><div class="row form-group form-row" *ngIf="modifyz">
            <ng-select class="form-control" id="field_methodez" name="methodez" [(ngModel)]="ouvrage.modeZSaisieId" class="col-md-8 dropdown-no-padding">
                <ng-option [value]="lexMethodeOption.id" *ngFor="let lexMethodeOption of lexmethodesz">{{lexMethodeOption.libelle}}</ng-option>
            </ng-select>
        </div>
        <div class="row form-row" *ngIf="modifyz && ouvrage.modeZSaisieId">
            <label class="col-md-5 form-control-label" for="field_precision">Précision absolue (cm) :</label>
        </div><div class="row form-group form-row" *ngIf="modifyz && ouvrage.modeZSaisieId">
            <input type="number" step="0.0000001" class="col-md-8 form-control" name="precision" id="field_precision" [(ngModel)]="ouvrage.precisionAbsolueZSaisie" />
        </div>
        <div class="row form-row" *ngIf="modifyz">
            <label class="col-md-5 form-control-label" for="field_appareil">Appareil utilisé :</label>
        </div><div class="row form-group form-row" *ngIf="modifyz">
            <input type="text" class="col-md-8 form-control" name="appareil" id="field_appareil" [(ngModel)]="ouvrage.appareilZSaisie" />
        </div>
        <div class="row form-row" *ngIf="modifyz && ouvrage.modeZSaisieId">
            <label class="col-md-5 form-control-label">Précision :</label>
        </div><div class="row form-group form-row" *ngIf="modifyz && ouvrage.modeZSaisieId">
            <label class="col-md-8 form-control">{{ouvrage.modeZSaisieId?getPrecisionZ(ouvrage.modeZSaisieId):''}}</label>
        </div>
    </div>

    <div class="form-group" *ngIf="isCarte">
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Système de référence spatial :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.srsId?ouvrage.srsLibelle:''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Unité : </label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.srsId?getUnite(ouvrage.srsId):''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">X :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.x | number:'0.5-5':'fr'}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Y :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.y | number:'0.5-5':'fr'}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Méthode utilisée :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.modeXYId?ouvrage.modeXYLibelle:''}}</label>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Précision :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.modeXYId?getPrecisionXY(ouvrage.modeXYId):''}}</label>
        </div>
        <div class="form-group">
            <h4>Altimétrie :</h4>
        </div>
        <div class="row form-row">
            <label class="col-md-5 form-control-label">Altitude calculée (en m) :</label>
        </div><div class="row form-group form-row">
            <label class="col-md-8 form-control">{{ouvrage.zcalc | number:'0.0-2':'fr'}}</label>
        </div>
        <div class="row form-row" *ngIf="ouvrage.modeZCalcId">
            <label class="col-md-5 form-control-label">Méthode utilisée :</label>
        </div><div class="row form-group form-row" *ngIf="ouvrage.modeZCalcId">
            <label class="col-md-8 form-control">{{ouvrage.modeZCalcId?ouvrage.modeZCalcLibelle:''}}</label>
        </div>
        <div class="row form-row" *ngIf="ouvrage.modeZCalcId">
            <label class="col-md-5 form-control-label">Précision :</label>
        </div><div class="row form-group form-row" *ngIf="ouvrage.modeZCalcId">
            <label class="col-md-8 form-control">{{ouvrage.modeZCalcId?getPrecisionZ(ouvrage.modeZCalcId):''}}</label>
        </div>
    </div>

    <div class="form-group">
        <h4 class="col-md-5 fs-title">Adresse de l'ouvrage</h4>
    </div>
    <dup-adresse-form [isPro]="false" [adresse]="ouvrage.adresse" [isOuvrage]="true"></dup-adresse-form>
    <div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_section">n° de section</label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="section" id="field_section" [(ngModel)]="ouvrage.numSection" />
    </div>
    <div class="row form-row">
        <label class="col-md-5 form-control-label" for="field_parcelle">n° de parcelle</label>
    </div><div class="row form-group form-row">
        <input type="text" class="col-md-8 form-control" name="parcelle" id="field_parcelle" [(ngModel)]="ouvrage.numParcelle" />
    </div>
    <div class="row form-row" hidden="true">
        <label class="col-md-5 form-control-label" for="field_commentaire">Commentaire(s)</label>
    </div>
    <div class="row form-group form-row" hidden="true">
        <input type="textarea" class="col-md-8 form-control" name="commentaire" id="field_commentaire" [(ngModel)]="ouvrage.commentaireNappe" />
    </div>
</div>

<!--<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
        <input type="button" name="next" class="btn btn-success btn-md float-right" value="Valider" (click)="validate()"  [disabled]="validateOuvrage()" />
    </div>
</div>-->
