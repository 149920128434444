import { Injectable, Component } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class PieceJointePopupService {
    private ngbModalRef: NgbModalRef;

    constructor(
    private modalService: NgbModal)
    {     
        this.ngbModalRef = null;
    }

    pieceJointeModalRef(component: Component, ouvrageId: number, ouvrageNom:string, declarationId: number, editionMode:boolean): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true});
        modalRef.componentInstance.ouvrageId = ouvrageId;
        modalRef.componentInstance.ouvrageNom = ouvrageNom;
        modalRef.componentInstance.declarationId = declarationId;
        modalRef.componentInstance.editionMode = editionMode;
        return modalRef;
    }

    rapportModalRef(component: Component, declarationId: number, declarationType:number, reglementationIds: any[]): NgbModalRef {
        const modalRef = this.modalService.open(component, { size: 'lg', backdrop: 'static', centered: true});
        modalRef.componentInstance.declarationId = declarationId;
        modalRef.componentInstance.declarationType = declarationType;
        modalRef.componentInstance.reglementationIds = reglementationIds;
        return modalRef;
    }

    close() {
        this.ngbModalRef.close();
    }
}