import { Acteur } from '../acteur/acteur.model';
import { Adresse } from '../adresse/adresse.model';
import { PieceJointe } from '../piece-jointe/piece-jointe.model';

export class Ouvrage {
    constructor(
        //Identification
        public id?: number,
        public tmpId?: number,
        public nomUsuel?: string,
        public indiceBss?: string,
        public designationBss?: string,
        //Localisation
        public modeLocalisation?: string,
        public x?: number,
        public y?: number,
        public precisionAbsolueXY?: number,
        public echelleCarteXY?: string,
        public zsaisie?: number,
        public precisionAbsolueZSaisie?: number,
        public appareilZSaisie?: string,
        public nomMntZSaisie?: string,
        public tailleMailleZSaisie?: number,
        public equidistanceZSaisie?: number,
        public zcalc?: number,
        public numSection?: string,
        public numFeuille?: number,
        public numParcelle?: string,
        public adresseId?: number,
        public geom?:any,

        //Caractéristiques
        public longeur?: number,
        public azimut?: number,
        public pendage?: number,
        public diametre?: number,
        public prelevement?: number,
        public debit?: number,
        public presenceMargelle?: boolean,
        public conformeNorme?: boolean,
        public reseauInterne?: boolean,
        public rejetUsees?: boolean,
        public rejetPluviales?: boolean,
        //Aquifère
        public codeNappe?: string,
        public natureNappe?: string,
        public nomNappe?: string,
        public commentaireNappe?: string,
        public eausuperfSource?:string,
        //Détails
        public saisieValide?: boolean,
        public dateCreation?: any,
        public dateModification?: any,
        //Acteurs
        public acteurProprioId?: number,
        public acteurMoeId?: number,
        public acteurForeurId?: number,
        public acteurExploitantId?: number,

        public declarationId?: number,
        public typeSaisieCoorId?: number,
        public typeLocalisationId?: number,
        public typeLocalisationLibelle?: string,
        public directionId?: number,
        public directionLibelle?:string,
        public modeXYId?: number,
        public modeXYLibelle?: string,
        public modeZSaisieId?: number,
        public modeZSaisieLibelle?: string,
        public modeZCalcId?: number,
        public modeZCalcLibelle?: string,
        public natureOuvrageId?: number,
        public natureOuvrageLibelle?:string,
        public operationId?: number,
        public precisionXYId?: number,
        public precisionXYLibelle?: string,
        public precisionZSaisieId?: number,
        public precisionZSaisieLibelle?: string,
        public precisionZCalcId?: number,
        public precisionZCalcLibelle?: string,
        public srsId?: number,
        public srsLibelle?:string,
        public statutId?:number,
        public statutLibelle?:string,
        public auteurCreation?: string,
        public auteurModification?: string,
        public acteurProprio?:Acteur,
        public acteurMoe?:Acteur,
        public acteurForeur?:Acteur,
        public adresse?:Adresse,
        public pieceJointes?:PieceJointe[],
    ) {
        this.saisieValide = false;
    }
}
