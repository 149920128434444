<div class="modal-header">
    <h2 class="modal-title">{{editionMode?"Ajouter une pièce jointe à l\'ouvrage ":"Liste des pièces jointes de l\'ouvrage "}} {{ouvrageNom}}</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-pj-popup">
    <div *ngIf="editionMode">
        <div class="row form-row">
            <label class="col-md-5 form-control-label" for="field_relation">Type de document <span class="asterisk-red">*</span></label>
        </div><div class="row form-row form-group">
            <ng-select id="field_typeDoc" name="typeDoc" [(ngModel)]="typeDocId" class="col-md-6 dropdown-no-padding">
                <ng-option [value]="lexTypeOption.id" *ngFor="let lexTypeOption of lexTypeDocs" [hidden]="!lexTypeOption.valide">{{lexTypeOption.libelle}}</ng-option>
            </ng-select>
        </div>
        <p [hidden]="!showErrorFileName" style="text-align: center; color: red ; font-weight: bold;" > Nom de fichier trop long (80 caractères, espaces compris maximum). Renommez votre fichier avant de le déposer. </p>
        <p [hidden]="!showErrorFileSize" style="text-align: center; color: red ; font-weight: bold;" > La taille de la pièce jointe dépasse la limite maximale (10 Mo). </p>
        <p-fileUpload #fileUpload mode="basic" [auto]="true" chooseLabel="Déposer votre fichier" maxFileSize="10485760" name="myFiles[]" customUpload="true" (onSelect)="onSelectUpload($event,fileUpload)" (uploadHandler)="myUploader($event, fileUpload)" accept=".pdf" (onUpload)="onUploaded($event)"></p-fileUpload>
    </div>
    <p-table [value]="piecesJointes" class="table table-striped">
        <ng-template pTemplate="header">
            <tr>
              <th scope="col">Nom</th>
              <th scope="col">Nature</th>
              <th scope="col">Date dépot</th>
              <th scope="col">Action</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-piecejointe>
            <tr>
              <td>
                <label>{{piecejointe.nomFichier}}</label>
              </td>
              <td>
                <label>{{piecejointe.docTypeId?piecejointe.docTypeLibelle:''}}</label><br/>
              </td>
              <td>
                <label>{{piecejointe.dateCreation | date:'dd/MM/yyyy'}}</label>
              </td>
              <td>
                  <button type="button" (click)="downloadFile(piecejointe)" class="btn btn-primary btn-sm" title="Télécharger la pièce jointe">
                    <span class="fas fa-file-download"></span>
                  </button>
                  <button *ngIf="editionMode" type="button" (click)="deleteFile(piecejointe)" class="btn btn-danger btn-sm" title="Supprimer la pièce jointe">
                    <span class="fas fa-times"></span>
                  </button>
                </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptyMessage">
            <tr>
              <td colspan="4">
                Aucun document associé à cet ouvrage.
              </td>
            </tr>
          </ng-template>
    </p-table>
</div>
<div class="modal-footer">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Fermer" (click)="cancel()"/>
    </div>
</div>