import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'dup-modeletype-popup',
    templateUrl: './modeletypeinfocompl-popup.component.html',
    styleUrls: ['./popup.css']
  })
  export class ModeleTypeInfoComplPopupComponent implements OnInit {
  
    // declaration en cours
  
    constructor(
      private ngbModal: NgbActiveModal,
    ) { }
  
    ngOnInit() {
  
    }
  
    validate() {
    }
  
    cancel() {
      this.ngbModal.dismiss();
    }
  
  }