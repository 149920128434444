import { Acteur } from '../acteur/acteur.model';
import { Commune } from '../commune/commune.model';
import { Departement } from '../departement/departement.model';
import { Region } from '../region/region.model';
import { LexReglementation } from '../lex-reglementation/lex-reglementation.model';

export class Declaration {
    constructor(
        public id?: number,
        public nomProjet?: string,
        public plusieursOuvrages?: boolean,
        public dateDebutTravaux?: any,
        public dateFinTravaux?: any,
        public prelevement?: number,
        public dateCreation?: any,
        public dateSoumission?: any,
        public dateFinalisation?: any,
        public dateModification?: any,
        public acteurMoaId?: number,
        public fonctionId?: number,
        public fonctionLibelleComplet?: string,
        public substanceId?: number,
        public substanceLibelle?: string,
        public usageId?: number,
        public usageLibelle?:string,
        public statutDeclarationId?: number,
        public statutDeclarationLibelle?:string,
        public regimeIcpeId?: number,
        public regimeIcpeLibelle?:string,
        public relationId?: number,
        public relationLibelle?:string,
        public typeDeclarationId?: number,
        public typeDeclarationLibelle?:string,
        public typeIcpeId?: number,
        public typeIcpeLibelle?:string,
        public auteurCreation?: string,
        public auteurSoumission?: string,
        public auteurFinalisation?: string,
        public acteurMoa?:Acteur,
        public communes?:Commune[],
        public departements?:Departement[],
        public regions?:Region[],
        public reglementations?:LexReglementation[],
        public reglementation?: string,
        public cerfaLoading?:boolean,
        public rapportExists?:boolean,
        public autreUsagePrecision?:string,
        public acteurTiers?:Acteur,
        public acteurTiersId?: number,
        public commentaire?: string
    ) {
        //this.plusieursOuvrages = false;
        this.communes = [];
        this.departements = [];
        this.regions = [];
        this.reglementations = [];
        this.cerfaLoading = false;
    }
}
