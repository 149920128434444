import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Principal } from '../auth/principal.service';
import { PieceJointeService } from 'src/app/entities/piece-jointe/piece-jointe.service';
import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';
import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { FileUpload } from 'primeng/fileupload';
import { DeclarationService } from 'src/app/entities/declaration/declaration.service';



@Component({
    selector: 'dup-final-popup',
    templateUrl: './finalisation-popup.component.html',
    styleUrls: ['./popup.css']
  })
  export class FinalisationPopupComponent implements OnInit {
  
    // declaration en cours
    @Input() declarationId: number;
    @Input() declarationType: number;
    @Input() reglementationIds: any[]; 

    ouvrages:Ouvrage[];

    showErrorFileName:boolean = false;
    showErrorFileSize:boolean = false;

    dialogUrl:any;

    fileData:FormData;

    ready:Boolean = false;
    isLoading:Boolean = false;
    value = 0;
    interval;
    isFordom:boolean = false;
  
    constructor(
      private ngbModal: NgbActiveModal,
      private principal: Principal,
      private pieceJointeService:PieceJointeService,
      private ouvrageService:OuvrageService,
      private declarationService: DeclarationService
    ) { }
  
    ngOnInit() {
        this.principal.getDialogUrl().then((url) => {this.dialogUrl = url;});
        this.ouvrageService.findByDeclaration(this.declarationId.toString()).subscribe((res) => {
          this.ouvrages = res;
        });

        this.isFordom = this.reglementationIds.includes(4050)
    }

    openDialog() {
      this.ngbModal.dismiss();
      window.open(this.dialogUrl+this.ouvrages[0].id, "_blank");
    }

    onSelectUpload(event, uploader: FileUpload){
      this.showErrorFileSize = (event.files[0] as File).size > 10485760;
      this.showErrorFileName = (event.files[0] as File).name.length > 80;

      if(this.showErrorFileSize || this.showErrorFileName){
        uploader.remove(event, 0);
      }
    }

    myUploader(event) {
      this.fileData = new FormData();
      this.fileData.append("fichier",event.files[0] as File, (event.files[0] as File).name);
      this.fileData.append("declarationId",this.declarationId.toString());
      this.fileData.append("nomFichier", (event.files[0] as File).name);
      this.ready = true;
    }

    validate() {
      this.isLoading = true;
      this.interval = setInterval(() => {
        if (this.value < 100) this.value += 1;
        else {
          clearInterval(this.interval);
        }
      },250)

      if (this.declarationType == 1800){

        this.declarationService.finaliseRegul(this.declarationId).subscribe(res => {
          this.isLoading = false;
          this.ngbModal.close();
        }, err => {
          this.isLoading = false;
          this.ngbModal.dismiss("Error");
        });
      }else{
        this.pieceJointeService.createRapport(this.fileData).subscribe(res => {
          this.isLoading = false;
          this.ngbModal.close();
        }, err => {
          this.isLoading = false;
          this.ngbModal.dismiss("Error");
        });
      }

      
    }
  
    cancel() {
      this.ngbModal.dismiss();
    }
  
  }