<div class="modal-header">
    <h2 class="modal-title">Confirmation de déclaration (régularisation d’ouvrage existant)</h2>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="cancel()" [hidden]="!isSaving">&times;</button>
</div>
<div class="modal-body col-md-10 offset-md-1" id="dup-soumission-popup">
    <div class="saving" [hidden]="!isSaving">
        <p-progressBar [value]="value"></p-progressBar>
    </div>
    <div [hidden]="isSaving" *ngIf="typeReglementation == 'MIN'">
        <p>En cliquant sur Valider, la déclaration est notifiée à la D(R)EAL, ainsi qu’aux services de l’État qui en auront fait la demande. Vous recevrez ainsi que les différents acteurs de la déclaration un récépissé de votre déclaration par courriel. Cette déclaration ne vaut pas autorisation de réaliser l'ouvrage au titre des autres réglementations qui s'appliquent à votre projet. <strong>Une fois transmise, la déclaration ne peut plus être modifiée.</strong></p>
        <p><strong> Cette transmission entraine automatiquement la finalisation de la procédure, ce qui vous permettra d'obtenir un code BSS pour votre (vos) ouvrage(s) réalisé(s).</strong></p>
        <p>Voulez-vous continuer ?</p>
    </div>
    <div [hidden]="isSaving" *ngIf="typeReglementation == 'FORDOM'">
        <p>Cette déclaration va vous permettre de générer le Cerfa N°13837*02 relatif à la déclaration d’ouvrage, prélèvements, puits et forages à usage domestique. <strong>Dans le cas où l'(les) ouvrage(s) ferai(en)t plus de 10 m de profondeur, une déclaration au titre de l'article L.411-1 du Code Minier est automatiquement associée</strong>, et un récépissé est envoyé par courriel.</p>
        <p>Dans le cas d'un usage pour la consommation humaine unifamiliale, nous rappelons qu'une analyse de l’eau de type P1, à l’exception du chlore, définie dans l’arrêté du 11 janvier 2007 (relatif au programme de prélèvements et d’analyses du contrôle sanitaire pour les eaux fournies par un réseau de distribution) doit être réalisée et jointe à la déclaration ; pour les cas de consommation autre qu'unifamilial, une autorisation préfectorale doit être demandée au titre de l’article L.1321-7 du code de la santé publique.</p>
        <p>En cliquant sur Valider, le Cerfa généré sera transmis à la mairie de la commune et à la DDT(M). En cas de déclaration Code minier associée, celle-ci est notifiée à la D(R)EAL, ainsi qu’aux services de l’État qui en auront fait la demande. Vous recevrez ainsi que les différents acteurs de la déclaration un courriel comprenant les mêmes documents.<strong>Une fois transmise, la déclaration ne peut plus être modifiée. Cette transmission entraine automatiquement la finalisation de la procédure, ce qui vous permettra d'obtenir un code BSS pour votre (vos) ouvrage(s) réalisé(s).</strong> </p>
        <p>Voulez-vous continuer ?</p>
    </div>
</div>
<div class="modal-footer" [hidden]="isSaving">
    <div class="col-md-6">
        <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
        <input type="button" name="next" class="btn btn-primary btn-md float-right" value="Valider" (click)="validate()" />
    </div>
</div>
