<form name="form" role="form" (ngSubmit)="save()" #settingsForm="ngForm" *ngIf="settingsAccount" novalidate>
    <h2 class="fs-title" style="text-align: center;">Mon compte</h2>
    <div class="row">
        <div class="col-md-8 offset-md-4">
            <div class="row form-row">
                <h4 class="col-md-12 fs-title">Mes informations personnelles</h4>
            </div>

            <div class="row form-row">
                <label class="col-md-5 form-control-label" for="identifiant">Identifiant</label>
            </div><div class="row form-group form-row">
                <input type="email" class="col-md-6 form-control" id="identifiant" name="identifiant" placeholder="identifiant"
                        [(ngModel)]="settingsAccount.email" minlength="5" maxlength="100" email disabled>
            </div>

            <div class=" row form-row">
                <label class="col-md-5 form-control-label" for="field_statut">Profil</label>
            </div><div class="row form-group form-row" [hidden]="settingsAccount.profilUtilisateurId == 4200">
                <input type="text"class="col-md-6 form-control" name="statut" id="field_statut"
                [(ngModel)]="settingsAccount.profilUtilisateurLibelle" disabled />
            </div>

            <div [hidden]="settingsAccount.profilUtilisateurId != 4200" class="col-md-6"
            style="border-radius: .25rem; padding-left : 20px; padding-bottom: 7px; border: 1px solid #ced4da; background-color: #e9ecef; margin-left: -5px; " >
            
                <input class="col-md-1" type="radio" checked="checked" [ngModelOptions]="{standalone: true}" />
                <span class="col-md-11">{{settingsAccount.profilUtilisateurLibelle}}</span>
                <div class="row form-row">
                    <span class="col-md-2"></span>
                    <label style="font-size: 14px ;" class="col-md-5 form-control-label">Demander un autre profil</label>
                </div>
                <div [hidden]="settingsAccount.demandeProfilUtilisateurId || demandeLoading">
                    <div class="row form-row col-md-12">
                        <span class="col-md-2"></span>
                            <span *ngFor="let data of profilUtilisateurAskable; let i=index"  style="margin-right : 10px">
                                <input style="margin-right : 10px" type="radio" [value]="data" [ngModelOptions]="{standalone: true}" [title]="computeDemandeProfilTooltip(data)" [(ngModel)]="profilToAsk"  />
                                <label style="font-size: 14px ;" [title]="computeDemandeProfilTooltip(data)" >{{data.libelle}}</label>
                            </span>
                        
                    </div>
                    <div class="row form-row col-md-12" style="justify-content: center;">
                        <input type="button" [disabled]="!profilToAsk" (click)="sendDemandeProfil()" class="btn btn-primary btn-md" value="Envoyer ma demande" />
                    
                    </div>
                </div>

                <div class="row form-row" [hidden]="!demandeLoading">
                    <label class="col-md-11 form-control-label" style="text-align:center">
                        Envoi en cours ...</label>
                </div>
                <div class="row form-row" [hidden]="!demandeError">
                    <label class="col-md-11 form-control-label" style="text-align:center">
                        Erreur lors de l'envoi</label>
                </div>
                
                <div class="row form-row" [hidden]="!settingsAccount.demandeProfilUtilisateurId || demandeLoading">
                    <label class="col-md-11 form-control-label" style="text-align:center">
                        Demande pour le profil <strong>{{settingsAccount.demandeProfilUtilisateurLibelle}}</strong> 
                        envoyée  le {{settingsAccount.dateDemandeProfil  | date: 'dd/MM/yyyy'}}.</label>
                        <label>Votre demande a été prise en compte et sera traitée dans les meilleurs délais</label>
                </div>

            </div>

            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label class="col-md-5 form-control-label">Type</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <span *ngFor="let lexStatutJuridiqueOption of lexstatutjuridiques"  [ngClass]="{'col-md-4': lexStatutJuridiqueOption.id == 3800, 'col-md-2': lexStatutJuridiqueOption.id != 3800}" > 
                    <input type="radio" class="col-md-1" name="statutJuridique" [value]="lexStatutJuridiqueOption.id" [ngModelOptions]="{standalone: true}" [(ngModel)]="settingsAccount.statutJuridiqueId" (click)="changeStatut(lexStatutJuridiqueOption)" />
                    <label class="col-md-11">{{computeStatutJuridiqueTitle(lexStatutJuridiqueOption)}}</label>
                </span>
            </div>

            <div class="row form-row">
                <label class="col-md-5 form-control-label" for="firstName">Prénom</label>
            </div><div class="row form-group form-row">
                <input type="text" class="col-md-6 form-control" id="firstName" name="firstName" placeholder="Votre prénom"
                    [(ngModel)]="contact.prenom" minlength=1 maxlength=50>
            </div>
            <div class="row form-row">
                <label class="col-md-5 form-control-label" for="lastName">Nom</label>
            </div><div class="row form-group form-row">
                <input type="text" class="col-md-6 form-control" id="lastName" name="lastName" placeholder="Votre nom"
                        [(ngModel)]="contact.nom" minlength=1 maxlength=50>
            </div>
            <div class="row form-row">
                <label class="col-md-5 form-control-label" for="email">Courriel de contact</label>
            </div><div class="row form-group form-row">
                <input type="text" class="col-md-6 form-control" id="email" name="email" placeholder="Votre email"
                        [(ngModel)]="contact.emailContact" minlength="5" maxlength="100" >
            </div>
            <div class="row form-row">
                    <label class="col-md-5 form-control-label">Accepter les notifications</label>
            </div>
            <div class="row form-group form-row">
                <span class="col-md-2">
                    <input type="radio" class="col-md-2" name="acceptNotif" id="acceptNotifTrue" [(ngModel)]="contact.acceptNotif" [value]="true" >
                    <label class="col-md-10">Oui</label>
                </span>
                <span class="col-md-2">
                    <input type="radio" class="col-md-2" name="acceptNotif" id="acceptNotifFalse" [(ngModel)]="contact.acceptNotif" [value]="false" >
                    <label class="col-md-10">Non</label>
                </span>
            </div>
            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label class="col-md-5 form-control-label" for="field_tel1">Téléphone fixe</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <input type="text" class="col-md-6 form-control" name="tel1" id="field_tel1"
                    [(ngModel)]="contact.tel1" minlength="10" maxlength="10"/>
            </div>
            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label class="col-md-5 form-control-label" for="field_tel2">Téléphone portable</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <input type="text" class="col-md-6 form-control" name="tel2" id="field_tel2"
                    [(ngModel)]="contact.tel2" minlength="10" maxlength="10"/>
            </div>
            <div class="form-row" [hidden]="!isPro">
                <h4 class="col-md-12">Coordonnées de l'établissement</h4>
            </div>
            <div class="row form-row" *ngIf="isPro" title="Saisir le siret sans espace">
                <label class="col-md-5 form-control-label" for="field_siret">Siret (14 chiffres) <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="isPro">
                <input type="text" class="col-md-4 form-control" name="siret" id="field_siret" pattern="[0-9]{14}" required (input)="validSiret()"
                    [(ngModel)]="settingsAccount.siretEtablissement" maxlength="14"/>
                <input type="button" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" (click)="findBySiret()" style="margin-top:-5px"/>
                <span *ngIf="!isValidSiret" style="color: red;"><span class="fas fa-times"></span> Format attendu : 12345678901234</span>
            </div>
            <div class="row form-row" *ngIf="isPro">
                <label class="col-md-5 form-control-label" for="field_rs">Raison sociale <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="isPro">
                <input type="text" class="col-md-6 form-control" name="rs" id="field_rs" required
                    [(ngModel)]="settingsAccount.raisonSocialeEtab" maxlength="200"/>
            </div>
            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label class="col-md-5 form-control-label" for="field_pays">Pays</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <span class="col-md-2">
                    <input type="radio" class="col-md-2" name="pays" id="paysFrance" [(ngModel)]="isFrance"
                        [value]="true" (click)="changeToFrance()"/>
                    <label for="paysFrance" class="col-md-10">France</label>
                </span>
                <span class="col-md-4">
                    <input type="radio" class="col-md-2" name="pays" id="paysAutre" [(ngModel)]="isFrance"
                        [value]="false"/>
                    <label for="paysAutre" class="col-md-10">Autre (préciser)</label>
                </span>
            </div>
            <div class="row form-group form-row" *ngIf="!(isAdmin || isBSS || isFrance)">
                <input type="text" class="col-md-6 form-control" name="paysText" id="field_pays"
                    [(ngModel)]="adresse.pays" maxlength="50" [disabled]="isFrance"/>
            </div>
            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label class="col-md-5 form-control-label" for="field_nomVoie">Adresse</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <input type="text" class="col-md-6 form-control" name="nomVoie" id="field_nomVoie"
                    [(ngModel)]="adresse.nomVoie" maxlength="100" />
            </div>
            <div class="row form-row" *ngIf="!(isAdmin || isBSS)">
                <label *ngIf="isFrance" class="col-md-5 form-control-label" for="field_codePostal">Code postal <span class="asterisk-red">*</span></label>
                <label *ngIf="!isFrance" class="col-md-5 form-control-label" for="field_codePostal">Zip code <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)">
                <input type="text" class="col-md-4 form-control" name="codePostal" id="field_codePostal"
                    [(ngModel)]="adresse.codePostal" maxlength="5" required />  
                <input type="button" *ngIf="isFrance" name="previous" class="col-md-2 btn btn-success btn-md" value="Rechercher" (click)="findCity($event)" [disabled]="!adresse.codePostal || adresse.codePostal.length!=5" style="margin-top:-5px"/>
            </div>

            <div class="row form-row" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS) && isFrance">
                <label class="col-md-5 form-control-label" for="field_region">Région <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS) && isFrance" [hidden]="!isFrance">
                <ng-select class="col-md-6 dropdown-no-padding" required id="field_region" name="region" [(ngModel)]="region" [items]="regions" bindLabel="nomReg" (change)="onSelectRegion($event)">
                </ng-select>
            </div>

            <div class="row form-row" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS) && isFrance">
                <label class="col-md-5 form-control-label" for="field_departement" [hidden]="!region">Département <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS) && isFrance" [hidden]="!isFrance">
                <ng-select class="col-md-6 dropdown-no-padding" required id="field_departement" name="departement" [(ngModel)]="departement" [items]="departements" bindLabel="nomDep" (change)="onSelectDepartement($event)" [hidden]="!region">
                </ng-select>
            </div>
            <div class="row form-row" [hidden]="!isFrance" *ngIf="!(isAdmin || isBSS) && isFrance">
                <label class="col-md-5" for="field_commune" [hidden]="!departement">Commune <span class="asterisk-red">*</span></label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS) && isFrance" [hidden]="!isFrance">
                <ng-select class="col-md-6 dropdown-no-padding" required id="field_commune" name="commune" [(ngModel)]="commune" [items]="communes" bindLabel="nomCom" [loading]="communeLoading" (change)="onSelectCommune($event)" [hidden]="!departement">
                </ng-select>
            </div>

            <div class="row form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
                <label class="col-md-5" for="field_complement">Complément</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
                <input type="text" class="col-md-6" name="complement" id="field_complement"
                    [(ngModel)]="adresse.complement" maxlength="50"/>
            </div>

            <div class="row form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
                <label class="col-md-5" for="field_lieuDit">Lieu-dit</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="!isFrance">
                <input type="text" class="col-md-6" name="lieuDit" id="field_lieuDit"
                    [(ngModel)]="adresse.lieuDit" maxlength="50"/>
            </div>

            <div class="row form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="isFrance">
                <label class="col-md-5 form-control-label" for="field_lieuDit">Ville</label>
            </div><div class="row form-group form-row" *ngIf="!(isAdmin || isBSS)" [hidden]="isFrance">
                <input type="text" class="col-md-6 form-control" name="ville" id="field_Ville"
                    [(ngModel)]="adresse.lieuDit" maxlength="50"/>  
            </div>
            
            <div class="row form-row" [hidden]="!isFrance" *ngIf="isPro">
                <label class="col-md-5 form-control-label" for="field_bp">Bp</label>
            </div><div class="row form-group form-row" *ngIf="isPro" [hidden]="!isFrance">
                <input type="text" class="col-md-6 form-control" name="bp" id="field_bp"
                    [(ngModel)]="adresse.bp" maxlength="50"/>
            </div>

            <div class="row form-row" [hidden]="!isFrance" *ngIf="isPro">
                <label class="col-md-5 form-control-label" for="field_cedex">Cedex</label>
            </div><div class="row form-group form-row" *ngIf="isPro" [hidden]="!isFrance">
                <input type="text" class="col-md-6 form-control" name="cedex" id="field_cedex"
                    [(ngModel)]="adresse.cedex" maxlength="50"/>
            </div>
            <div class="col-md-6" style="text-align: center;">
                <input type="button" name="previous" class="btn btn-secondary btn-md" value="Annuler" (click)="cancel()"/>
                <button type="submit" [disabled]="settingsForm.form.invalid" class="btn btn-primary btn-md">Sauvegarder</button>
            </div>
        </div>
    </div>
</form>