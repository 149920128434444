import { LexFonction } from 'src/app/entities/lex-fonction/lex-fonction.model';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDatepickerI18n, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
} from '@angular/forms';

import * as _ from 'lodash';
import { saveAs } from "file-saver";

import { Declaration } from '../../entities/declaration/declaration.model';
import { Utilisateur } from '../../entities/utilisateur/utilisateur.model';
import { Ouvrage } from 'src/app/entities/ouvrage/ouvrage.model';
import { DateI18nFormater, I18n } from 'src/app/shared/model/datepicker';
import { Commune } from 'src/app/entities/commune/commune.model';
import { DepartementService } from 'src/app/entities/departement/departement.service';
import { RegionService } from 'src/app/entities/region/region.service';
import { DeclarationReglementationService } from 'src/app/entities/declaration-reglementation/declaration-reglementation.service';
import { AbandonPopupService } from 'src/app/shared/popup/abandon-popup.service';
import { DeclarationService } from 'src/app/entities/declaration/declaration.service';
import { OuvrageService } from 'src/app/entities/ouvrage/ouvrage.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { DateUtils } from 'src/app/shared/model/date-utils';
import { ITEMS_PER_PAGE } from 'src/app/shared/constants/pagination.constants';
import { LexStatutDeclaration } from 'src/app/entities/lex-statut-declaration/lex-statut-declaration.model';
import { LexTypeDeclaration } from 'src/app/entities/lex-type-declaration/lex-type-declaration.model';
import { LexReglementation } from 'src/app/entities/lex-reglementation/lex-reglementation.model';
import { LexTypeDeclarationService } from 'src/app/entities/lex-type-declaration/lex-type-declaration.service';
import { LexStatutDeclarationService } from 'src/app/entities/lex-statut-declaration/lex-statut-declaration.service';
import { LexReglementationService } from 'src/app/entities/lex-reglementation/lex-reglementation.service';
import { DeletePopupComponent } from 'src/app/shared/popup/delete-popup.component';
import { AbandonPopupComponent } from 'src/app/shared/popup/abandon-popup.component';
import { PieceJointePopupComponent } from 'src/app/shared/popup/piecejointe-popup.component';
import { PieceJointePopupService } from 'src/app/shared/popup/piecejointe-popup.service';
import { FinalisationPopupComponent } from 'src/app/shared/popup/finalisation-popup.component';
import { PieceJointeService } from 'src/app/entities/piece-jointe/piece-jointe.service';
import { LexFonctionService } from 'src/app/entities/lex-fonction/lex-fonction.service';
import { LexProfilUtilisateur } from 'src/app/entities/lex-profil-utilisateur/lex-profil-utilisateur.model';
import { UtilisateurService } from 'src/app/entities/utilisateur/utilisateur.service';
import { UtilisateurDialogComponent } from 'src/app/entities/utilisateur/utilisateur-dialog.component';
import { UtilisateurPopupService } from 'src/app/entities/utilisateur/utilisateur-popup.service';

@Component({
  selector: 'dup-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: [
    'dashboard.css'
  ],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('rowExpansionTrigger', [
        state('void', style({
            transform: 'translateX(-10%)',
            opacity: 0
        })),
        state('active', style({
            transform: 'translateX(0)',
            opacity: 1
        })),
        transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    I18n,
    { provide: NgbDatepickerI18n, useClass: DateI18nFormater }]
})
export class DashboardComponent implements OnInit {
  account: Utilisateur;
  isInstructor: boolean;
  isDDT:boolean;
  mes_declarations: Declaration[];
  perimetre_declarations: Declaration[];
  acteur_declarations: Declaration[];
  totalDeclarations:number;
  totalPerimetreDeclarations: number;
  totalActeurDeclarations: number;
  ouvrages:Ouvrage[];
  ouvragesMap:Ouvrage[];
  page:any;
  itemsPerPage: any;
  collapsed:boolean;
  sortColumn:string;
  sortOrder:string;
  sortColumnUtilisateur:string;
  sortOrderUtilisateur:string;

  lexstatutdeclarations: LexStatutDeclaration[];
  lextypedeclarations: LexTypeDeclaration[];
  lexreglementations: LexReglementation[];
  lexfonctions: LexFonction[];

  filterId: string;
  filterName: string;
  filterStatut:number[];
  filterReg:number[];
  filterFon:number[];
  filterCommune: string;

  dateSoumission:Date[];
  dateDebutSoumission:any;
  dateFinSoumission:any;

  dialogUrl:any;

  currentPage:number;

  isLoading:Boolean;

  typeDeclaration: string;
  idReglementation: string;

  typedeclarationTitles:{};

  utilisateurs: Utilisateur[];
  totalUtilisateurs:number;
  pageUtilisateur:any;

  fr:any = {
    firstDayOfWeek: 1,
    dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
    dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
    dayNamesMin: ["Di", "Lu","Ma","Me","Je","Ve","Sa"],
    monthNames: [ "Janvier","Février","Mars","Avril","Mai","Juin","Juillet","Août","Septembre","Octobre","Novembre","Décembre" ],
    monthNamesShort: [ "Jan", "Fév", "Mar", "Avr", "Mai", "Juin","Juil", "Aoû", "Sep", "Oct", "Nov", "Déc" ],
    today: 'Aujourd\'hui',
    clear: 'Vider',
    dateFormat: 'dd/mm/yy',
    weekHeader: 'Sem'
  };

  constructor(
    private principal: Principal,
    private alertService: AlertService,
    private declarationService: DeclarationService,
    private ouvrageService: OuvrageService,
    private lexTypeDeclarationService: LexTypeDeclarationService,
    private lexStatutDeclarationService: LexStatutDeclarationService,
    private lexReglementationService: LexReglementationService,
    private lexFonctionService: LexFonctionService,
    private departementService:DepartementService,
    private regionService:RegionService,
    private declarationReglementationService:DeclarationReglementationService,
    private abandonPopupService: AbandonPopupService,
    private pieceJointePopupService: PieceJointePopupService,
    private pieceJointeService: PieceJointeService,
    private dateUtils: DateUtils,
    private router: Router,
    private utilisateurService: UtilisateurService,
    private utilisateurPopupService: UtilisateurPopupService
  ) {
    this.page = 0;
    this.pageUtilisateur = 0;
    this.itemsPerPage = ITEMS_PER_PAGE;
    this.isInstructor = null;
    this.isDDT = null;
    this.collapsed = true;
    this.filterId = "";
    this.filterName = "";
    this.filterCommune = "";
    this.sortColumn = "id";
    this.sortOrder = "DESC";
    this.sortColumnUtilisateur = "profilUtilisateurId,dateDemandeProfil";
    this.sortOrderUtilisateur = "ASC";
    this.isLoading = false;
    this.filterReg = [];
    this.filterFon = [];
    this.filterStatut = [];
    this.typedeclarationTitles = {"1550" :"Nouveau projet", "1800" : "Régularisation" };
  }

  ngOnInit() {
    this.typeDeclaration = sessionStorage.getItem('typeReglementation');
    
    this.principal.getDialogUrl().then((url) => {this.dialogUrl = url;});

    this.lexReglementationService.query().subscribe((res) => {
      this.lexreglementations = res.filter( (r) => r.id == 3850 || r.id == 4050 ) ;
    }, (res) => this.onError(res));

    this.principal.identity().then((res)=> { 
      if(res.profilUtilisateurId == 4400){
        this.isDDT = true;
        this.fetchUtilisateurs();
      } else {
        this.isDDT = false;
      }
    }, (err) => {this.isDDT = false;});
    this.isDDT = false;

    this.principal.isInstructor().then((res) => {
      this.isInstructor = res;
      this.collapsed = false;
      this.lexStatutDeclarationService.query()
        .subscribe((res) => {
          this.lexstatutdeclarations = res;
          if(this.isInstructor)
          {
            this.lexstatutdeclarations.splice(0,1);
          }
          this.lexstatutdeclarations.sort((a, b) => a.id - b.id);
          this.lexFonctionService.query().subscribe((res) => {
            this.lexfonctions = res;
            this.principal.identity().then((account) => {
              this.account = account;
              if(this.account) {
                if(this.account.adresseId == null) {
                    this.router.navigate(['/settings',{}]);
                } else {
                  this.search();
                }
              }
          }, (res) => this.onError(res));
        }, (res) => this.onError(res));
      }, (res) => this.onError(res));
    });
    
  }

  loadUtilisateursLazy(event) {
    this.pageUtilisateur = event.first/this.itemsPerPage;
    if(event.sortField)
        this.sortColumnUtilisateur = event.sortField;
    if(event.sortOrder == 1)
    {
        this.sortOrderUtilisateur = "ASC";
    }
    else if(event.sortOrder == 2) {
        this.sortOrderUtilisateur = "DESC";
    }
    this.fetchUtilisateurs();
}

  fetchUtilisateurs(){
    this.utilisateurService.queryForValidationCommunal({
      page: this.pageUtilisateur,
      size: this.itemsPerPage,
      sort: this.sortColumnUtilisateur.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`) + "," + this.sortOrderUtilisateur,
    }).subscribe(
      (res) => {
          this.utilisateurs = res.utilisateurs;
          this.totalUtilisateurs = res.maxUtilisateur;
      },
      (res) => this.onError(res)
  );
  }

  exportUtilisateurs(){
    this.isLoading = true;
    this.utilisateurService.exportCommunal().subscribe(
        (res) => {
            var newBlob = new Blob([res], { type: "application/csv" });
            saveAs(newBlob, "export.csv");
            this.isLoading = false;
            this.utilisateurService.deleteExport().subscribe(res => {});
        },
        (res) => {this.onError(res); this.isLoading = false;}
    );

}

  /**
   * Récupération des ouvrages liés à une déclaration passée en entrée
   * @param dataEvent Déclaration sur laquelle on a cliqué pour obtenir la liste des ouvrages
   */
  getOuvrages(dataEvent) {
    this.declarationService.find(dataEvent.id).subscribe( (res) => { 
      let index;
      if( this.mes_declarations && (index = this.mes_declarations.findIndex((i) => i.id == dataEvent.id) ) >=0 ) {
        this.mes_declarations[index].statutDeclarationLibelle = res.statutDeclarationLibelle ;
        this.mes_declarations[index].statutDeclarationId = res.statutDeclarationId ;
        this.mes_declarations[index].dateSoumission = res.dateSoumission ;
        this.mes_declarations[index].dateFinalisation = res.dateFinalisation ;
        this.mes_declarations[index].dateModification = res.dateModification ;
      }
      if((index = this.acteur_declarations.findIndex((i) => i.id == dataEvent.id) ) >=0 ) {
        this.acteur_declarations[index].statutDeclarationLibelle = res.statutDeclarationLibelle ;
        this.acteur_declarations[index].statutDeclarationId = res.statutDeclarationId ;
        this.acteur_declarations[index].dateSoumission = res.dateSoumission ;
        this.acteur_declarations[index].dateFinalisation = res.dateFinalisation ;
        this.acteur_declarations[index].dateModification = res.dateModification ;
      }
      if( this.perimetre_declarations && (index = this.perimetre_declarations.findIndex((i) => i.id == dataEvent.id) ) >=0 ) {
        this.perimetre_declarations[index].statutDeclarationLibelle = res.statutDeclarationLibelle ;
        this.perimetre_declarations[index].statutDeclarationId = res.statutDeclarationId ;
        this.perimetre_declarations[index].dateSoumission = res.dateSoumission ;
        this.perimetre_declarations[index].dateFinalisation = res.dateFinalisation ;
        this.perimetre_declarations[index].dateModification = res.dateModification ;
      }
    
    });
    this.ouvrageService.findByDeclaration(dataEvent.id).subscribe((res) => {
      this.ouvrages = res;
    }, (res) => this.onError(res));
  }

  changeNbInPage($event){
    this.itemsPerPage = $event.currentTarget.value;
    this.page = 0;
    this.search();
  }

  openDialog(id) {
    window.open(this.dialogUrl+id, "_blank");
  }

  private onSuccess(data, inst) {
    console.log(data.declarations);
    if(inst){
      this.perimetre_declarations = data.declarations;
      this.totalPerimetreDeclarations = data.maxDeclaration;
    }else {
      this.mes_declarations = data.declarations;
      this.totalDeclarations = data.maxDeclaration;
    }
    
    _.forEach(data.declarations, (declaration:Declaration) => {
      this.pieceJointeService.rapportExists(declaration.id).subscribe(res => {
        declaration.rapportExists = res === "true";
      }, err=> {
        declaration.rapportExists = false;
      } );
      this.ouvrageService.findCommunesByDeclaration(declaration.id).subscribe((res) => {
        declaration.communes = res;
        if(this.isInstructor){
          let departements = [];
          let regions = [];
          _.forEach(declaration.communes, (commune:Commune) => {
            if(!_.includes(departements, commune.inseeDep)) departements.push(commune.inseeDep);
            if(!_.includes(regions, commune.inseeReg)) regions.push(commune.inseeReg);
          });
          _.forEach(departements, (dep:string)=> {
            this.departementService.find(dep).subscribe((res) => {
              declaration.departements.push(res);
            });
          });
          _.forEach(regions, (reg:string)=> {
            this.regionService.find(reg).subscribe((res) => {
              declaration.regions.push(res);
            });
          });
        }
      });
      if(this.isInstructor) {
        this.declarationReglementationService.findByDeclaration(declaration.id).subscribe((res) => {
          _.forEach(res, (reglementation:LexReglementation)=> {
            let doublon = false;
            _.forEach(declaration.reglementations, (reg:LexReglementation)=> {
              if(reg.id == reglementation.id) doublon = true;
            });
            if(!doublon) {
              declaration.reglementations.push(reglementation);
            }
          });
        });
      }
    });
  }

  private onSuccessActeur(data) {
    this.acteur_declarations = data.declarations;
    this.totalActeurDeclarations = data.maxDeclaration;
    _.forEach(this.acteur_declarations, (declaration:Declaration) => {
      this.ouvrageService.findCommunesByDeclaration(declaration.id).subscribe((res) => {
        declaration.communes = res;
      });
    });
  }

  private onError(error:any) {
    this.alertService.error(error.message, null, null);
  }

  filterStatus(value:any) {
    
    if (value.target.checked && !this.filterStatut.includes(value.target.value)) {
      this.filterStatut.push(value.target.value)
    }else {
      this.filterStatut.splice(this.filterStatut.indexOf(value.target.value), 1)
    }
    this.search();
    this.searchActeur();
  }

  filterFonctions(value:LexFonction[]) {
    this.filterFon = [];
    if(value.length>0) {
      value.forEach(val => this.filterFon.push(val.id));
    } /*else {
      this.lexstatutdeclarations.forEach(val => this.filterStatut.push(val.id));
    }*/
    this.search();
  }

  filterReglementation(value:any) {
    if (value.target.checked && !this.filterReg.includes(value.target.value)) {
      this.filterReg.push(value.target.value)
    }else {
      this.filterReg.splice(this.filterReg.indexOf(value.target.value), 1)
    }
    
    this.search();
  }

  filterDateSoumission(){
    this.search();
  }

  checkFilterId(){

    if(this.filterId && Number.isNaN(Number(this.filterId)) ){
      if(this.isInstructor){
        this.perimetre_declarations = [];
        this.totalPerimetreDeclarations = 0;
      }
      this.mes_declarations = [];
      this.totalDeclarations = 0
      return;
    }
    this.search();
  }

  checkFilterCommune(){
    this.search();
  }

  private getId(item:any) {
    return item.id;
  }

  loadDeclarationLazy(event) {
    this.page = event.first/this.itemsPerPage;
    if(event.sortField)
      this.sortColumn = event.sortField;
    if(event.sortOrder == 1)
    {
      this.sortOrder = "ASC";
    }
    else {
      this.sortOrder = "DESC";
    }
    this.search();
  }

  loadDeclarationActeurLazy(event) {
    this.page = event.first/this.itemsPerPage;
    if(event.sortField)
      this.sortColumn = event.sortField;
    if(event.sortOrder == 1)
    {
      this.sortOrder = "ASC";
    }
    else {
      this.sortOrder = "DESC";
    }
    this.searchActeur();
  }

  searchActeur() {
    if(!this.isInstructor) {
      this.collapsed = true;
    }
    if(this.filterStatut) {
      this.declarationService.currentUserActeur({
        page: this.page,
        size: this.itemsPerPage,
        sort: this.sortColumn + "," + this.sortOrder,
        statutIds: _.join(this.filterStatut,','),
        id: this.filterId,
        name: this.filterName
      }).subscribe(
        (res) => this.onSuccessActeur(res),
        (res) => this.onError(res)
      );
    }
  }

  regTooltip(regId){
    return regId == '3850' ? "Forages Code minier non domestiques" : "Forages domestiques hors Code minier<10 m";
  }

  search() {
    if(!this.isInstructor) {
      this.collapsed = true;
    }
    if(!this.filterStatut) {
      return; 
    }

    if (this.isInstructor == undefined || this.isInstructor == null){
      this.principal.isInstructor().then((isInst) => {
        this.isInstructor = isInst != undefined ? isInst : false;
        this.search();
      });
      return;
    }

    this.ouvragesMap = [];
    
    this.declarationService.currentUser({
      page: this.page,
      size: this.itemsPerPage,
      sort: this.sortColumn + "," + this.sortOrder,
      statutIds: _.join(this.filterStatut,','),
      id: this.filterId,
      name: this.filterName,
      idsReglementation: _.join(this.filterReg,','),
      commune: this.filterCommune
    }).subscribe(
      (res) => this.onSuccess(res, false),
      (res) => this.onError(res)
    );
    this.ouvrageService.currentUser({
      statutIds: _.join(this.filterStatut,','),
      fonctionIds: _.join(this.filterFon,','),
      id: this.filterId,
      name: this.filterName,
      commune: this.filterCommune
    }).subscribe(res => this.ouvragesMap.push(...res) );


    if(this.isInstructor) {
      this.declarationService.instructor({
        page: this.page,
        size: this.itemsPerPage,
        sort: this.sortColumn + "," + this.sortOrder,
        statutIds: _.join(this.filterStatut,','),
        fonctionIds: _.join(this.filterFon,','),
        idsReglementation: _.join(this.filterReg,','),
        startDate: this.dateUtils.convertLocalDateToServer(this.dateDebutSoumission),
        endDate: this.dateUtils.convertLocalDateToServer(this.dateFinSoumission),
        id: this.filterId,
        commune: this.filterCommune
      }).subscribe(
        (res) => this.onSuccess(res, true),
        (res) => this.onError(res)
      );
      this.ouvrageService.instructor({
        statutIds: _.join(this.filterStatut,','),
        reglementationIds: _.join(this.filterReg,','),
        fonctionIds: _.join(this.filterFon,','),
        startDate: this.dateUtils.convertLocalDateToServer(this.dateDebutSoumission),
        endDate: this.dateUtils.convertLocalDateToServer(this.dateFinSoumission),
        id: this.filterId,
        commune: this.filterCommune
      }).subscribe(res =>{ 
        this.ouvragesMap.push(...res); });
      }
  }

  export(instructeur:boolean) {
    this.isLoading = true;
    if(!instructeur) {
      this.declarationService.exportUser({
        page: 0,
        size: this.totalDeclarations,
        sort: this.sortColumn + "," + this.sortOrder,
        declarationId: this.filterId,
        statutIds: _.join(this.filterStatut,','),
        name: this.filterName
      }).subscribe(res => {
        var newBlob = new Blob([res], { type: "application/csv" });
        saveAs(newBlob, "export.csv");
        this.isLoading = false;
        this.declarationService.deleteExport().subscribe(res => {});
      });
    } else {
      this.declarationService.exportInstructor({
          page: 0,
          size: this.totalDeclarations,
          sort: this.sortColumn + "," + this.sortOrder,
          statutIds: _.join(this.filterStatut,','),
          reglementationIds: _.join(this.filterReg,','),
          startDate: this.dateUtils.convertLocalDateToServer(this.dateDebutSoumission),
          endDate: this.dateUtils.convertLocalDateToServer(this.dateFinSoumission),
          commune: this.filterCommune
      }).subscribe(res => {
        var newBlob = new Blob([res], { type: "application/csv" });
        saveAs(newBlob, "export.csv");
        this.isLoading = false;
        this.declarationService.deleteExport().subscribe(res => {});
      });
    }
  }

  seeDeclaration(declarationToSee:Declaration) {
    window.open(window.location.href+'declaration/'+declarationToSee.id, "_blank");
  }

  modifyDeclaration(declarationToModify:Declaration) {
    if (declarationToModify.reglementation.indexOf('Forage domestique') > -1){
      sessionStorage.setItem('typeReglementation','FORDOM');
    } else {
      sessionStorage.setItem('typeReglementation','MIN');
    }
    this.router.navigate(['declarationForm/'+declarationToModify.id,{type: declarationToModify.typeDeclarationId}]);
  }

  modifyOuvrage(ouvrageToModify:Ouvrage) {
    this.router.navigate(['foreurForm/'+ouvrageToModify.id]);
  }

  cancelDeclaration(declarationToCancel) {
    let modalRef:NgbModalRef = this.abandonPopupService.abandonModalRef(AbandonPopupComponent as Component, declarationToCancel.id);
     modalRef.result.then((result) => {
      this.search();
     }, (reason) => {});
  }

  utilisateurPopup(utilisateur) {
    let modalRef:NgbModalRef = this.utilisateurPopupService.utilisateurModalRef(UtilisateurDialogComponent as Component, utilisateur);
     modalRef.dismissed.subscribe((result) => {
      if(result != "cancel"){
        this.pageUtilisateur = 0;
        this.sortColumnUtilisateur = "profilUtilisateurId,dateDemandeProfil";
        this.sortOrderUtilisateur = "ASC";
        this.fetchUtilisateurs();
      } 
     }, (e) => {
     });
  }


  deleteDeclaration(declatationToDelete:Declaration) {
     let modalRef:NgbModalRef = this.abandonPopupService.abandonModalRef(DeletePopupComponent as Component, declatationToDelete.id);
     modalRef.result.then((result) => {
      this.search();
     }, (reason) => {});
  }

  telecharger(declarationToDownload:Declaration) {
    this.declarationService.download(declarationToDownload.id).subscribe(res => {
        var newBlob = new Blob([res], { type: "application/pdf" });
        saveAs(newBlob, "dcl_duplos_min_" + declarationToDownload.id + ".pdf");
        this.declarationService.deleteFile(declarationToDownload.id).subscribe(res => {});
    });
  }

  telechargerCerfa(declarationToDownload:Declaration) {
    declarationToDownload.cerfaLoading = true;
    this.declarationService.cerfa(declarationToDownload.id).subscribe(res => {
      var newBlob = new Blob([res], { type: "application/pdf" });
      saveAs(newBlob, "cerfa_" + declarationToDownload.id + ".pdf");
      declarationToDownload.cerfaLoading = false;
      this.declarationService.deleteCerfa(declarationToDownload.id).subscribe(res => {});
    });
  }

  isForageDomestique(declaration:Declaration) {
    return declaration.fonctionId != undefined && declaration.fonctionId == 5500 && declaration.usageId == 10750 && declaration.prelevement <= 1000;
  }

  openPieceJointe(ouvrageId, ouvrageNom, declarationId) {
    let modalRef: NgbModalRef = this.pieceJointePopupService.pieceJointeModalRef(PieceJointePopupComponent as Component, ouvrageId, ouvrageNom, declarationId, false);
    modalRef.result.then((result) => {
    }, (reason) => { });
  }

  openDialogFinForage(declaration, indiceBss) {
    this.ouvrageService.findByDeclaration(declaration.id).subscribe((res) => {
      let ouvrage = res[0];
      let url = this.dialogUrl.substring(0, this.dialogUrl.indexOf("/duplos/")) +
              "/pdf-reports/Rapport_de_fin_de_forage_DUPLOS" +
              "_" + ouvrage.id +
              "_public.pdf";
      var i = 0, urlLength = url.length;
      for(i; i < urlLength; i++) {
        url = url.replace(" ", "_");
      }
      window.open(url, "_blank");
    }, (res) => this.onError(res));
  }

  openFinalisation(declarationId, declarationTypeId, reglementations) {
    let modalRef: NgbModalRef = this.pieceJointePopupService.rapportModalRef(FinalisationPopupComponent as Component, declarationId, declarationTypeId, reglementations?.map(r=>r.id));
    modalRef.result.then((result) => {
      this.search();
    }, (reason) => { });
  }

  downloadRapport(id) {
    this.pieceJointeService.downloadRapport(id).subscribe(res => {
      var newBlob = new Blob([res], { type: "application/pdf" });
      saveAs(newBlob, "rapport_"+id + ".pdf");
    }, err => {
      this.ouvrageService.findByDeclaration(id).subscribe((res) => {
        window.open(this.dialogUrl+res[0].id, "_blank");
      });
    });
  }

  tooltip() {
    let tooltip = "En cours : Le projet est en cours de déclaration, toutes les informations sont modifiables et aucune notification n'a été envoyée.\x0A";
    tooltip += "Transmise : La déclaration a été transmise, donc notifiée à la D(R)EAL et aux acteurs du projet. Le code BSS sera généré lors de la prochaine étape.\x0A";
    tooltip +="Finalisée : Le projet a été réalisé. Le rapport de fin de forage a été téléchargé et donc transmis à la D(R)EAL, aux acteurs du projet et au BRGM pour bancarisation. Un code BBS est généré pour chacun des ouvrages.\x0A";
    tooltip +="Abandonnée : Le projet, déjà soumis, est finalement abandonné avant la réalisation des travaux; cet abandon est notifié à la D(R)EAL et aux acteurs concernés.\x0A";
    return tooltip;
  }

}
