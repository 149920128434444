import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgSelectModule } from '@ng-select/ng-select';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { DuplosSharedModule } from './shared/shared.module';
import { DuplosAppRoutingModule} from './app-routing.module';
import { DuplosHomeModule } from './home/home.module';
import { DuplosEntityModule } from './entities/entity.module';
import { PaginationConfig } from './blocks/config/uib-pagination.config';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { ConfirmDialogModule } from 'primeng/confirmdialog';

import {
    DupMainComponent,
    NavbarComponent,
    FooterComponent,
    ErrorComponent,
} from './layouts';

import { DuplosDeclarationFormModule } from './forms/forms.module';
import { AuthInterceptor } from './blocks/interceptor/auth.interceptor';
import { ErrorHandlerInterceptor } from './blocks/interceptor/errorhandler.interceptor';
import { NotificationInterceptor } from './blocks/interceptor/notification.interceptor';
import { AuthExpiredInterceptor } from './blocks/interceptor/auth-expired.interceptor';
import { UserRouteAccessService } from './shared/auth/user-route-access-service';

const interceptors = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthExpiredInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: NotificationInterceptor, multi: true }]; 

// the second parameter 'fr' is optional
registerLocaleData(localeFr, 'fr');


// AOT compilation support
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        DuplosAppRoutingModule,
        NgxWebstorageModule.forRoot({ prefix: 'duplos', separator: '-'}),
        DuplosSharedModule,
        DuplosHomeModule,
        DuplosEntityModule,
        DuplosDeclarationFormModule,
        HttpClientModule, 
        NgSelectModule, FormsModule,
        ConfirmDialogModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    declarations: [
        DupMainComponent,
        NavbarComponent,
        ErrorComponent,
        FooterComponent
    ],
    providers: [
        interceptors,
        PaginationConfig,
        UserRouteAccessService
    ],
    bootstrap: [ DupMainComponent ]
})
export class DuplosAppModule {}
