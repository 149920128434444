<div [ngSwitch]="isInstructor">
  <p-panel header="Filtres" [toggleable]="true" [style]="{'margin-bottom':'20px'}">
    <div class="row form-row">
      <span class="col-md-4">N° de déclaration</span><div class="col-md-5"><input type="search" class="form-control" [(ngModel)]="filterId" (keyup)="checkFilterId()" (search)="checkFilterId()" placeholder="Filtrer..."></div>
      <span *ngSwitchCase="false" class="col-md-4">Nom du projet</span><div *ngSwitchCase="false" class="col-md-5"><input type="search" class="form-control" [(ngModel)]="filterName" (keyup)="search(); searchActeur();" (search)="search(); searchActeur();" placeholder="Filtrer..."></div>
      <span class="col-md-4">Statut</span><div class="col-md-5">
      <div *ngFor="let data of lexstatutdeclarations; let i=index">
        <label style="width: 25%; float: left; ">
          <input type="checkbox" [value]="data.id" (change)="filterStatus($event)"  />
          {{data.libelle}}
        </label>
      </div>
      </div>
      <span class="col-md-4">Réglementation</span><div class="col-md-5">
        <div *ngFor="let data of lexreglementations; let i=index">
          <label style="width: 33%; float: left; " [title]="regTooltip(data.id)">
            <input type="checkbox" [value]="data.id" (change)="filterReglementation($event)"  />
            {{data.libelle}}
          </label>
        </div>
        <label style="width: 33%; float: left; " title="Forages domestiques soumis au Code minier &gt; 10 m">
          <input type="checkbox" [value]="-1" (change)="filterReglementation($event)"  />
          FD &amp; CM
        </label>
      </div>
      <span *ngSwitchCase="true" class="col-md-4">Fonction</span><div class="col-md-5" *ngSwitchCase="true"><p-multiSelect [options]="lexfonctions" optionLabel="libelleComplet" defaultLabel="Filtrer..." [style]="{'width':'100%'}" (onChange)="filterFonctions($event.value)"></p-multiSelect></div>
      <span *ngSwitchCase="true" class="col-md-4">Date de transmission</span><div *ngSwitchCase="true" class="col-md-5">Du <p-calendar [(ngModel)]="dateDebutSoumission" [locale]="fr" dateFormat="dd/mm/yy" [readonlyInput]="true" [showButtonBar]="true" [showIcon]="true" (ngModelChange)="filterDateSoumission()" title="Filtrer..."></p-calendar> Au <p-calendar [(ngModel)]="dateFinSoumission" [locale]="fr" dateFormat="dd/mm/yy" [readonlyInput]="true" [showButtonBar]="true" [showIcon]="true" (ngModelChange)="filterDateSoumission()" title="Filtrer..."></p-calendar></div>
      <span *ngSwitchCase="true" class="col-md-4">Commune</span><div *ngSwitchCase="true" class="col-md-5"><input type="search" class="form-control" [(ngModel)]="filterCommune" (keyup)="checkFilterCommune()" (search)="checkFilterCommune()" placeholder="Filtrer..."></div>
      
    </div>
  </p-panel>
  <p-tabView>

    <p-tabPanel *ngIf="isDDT == null || isDDT">
      <ng-template pTemplate="header">Comptes communaux</ng-template>
      <div>
        <button type="button" name="export" class="btn btn-primary btn-md float-right" (click)="exportUtilisateurs()" title="Exporter le tableau en CSV">
          Exporter
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        </button>
      </div>
        <dup-alert-error></dup-alert-error>
        <div class="table-responsive" *ngIf="utilisateurs">
            <p-table class="table table-striped" [resetPageOnSort]="false" [value]="utilisateurs" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalUtilisateurs" dataKey="id"  (onLazyLoad)="loadUtilisateursLazy($event)">
                <ng-template pTemplate="header">
                    <tr>
                        <th scope="col" [pSortableColumn]="'id'" style="width: 5em;"><span>Id Utilisateur</span><p-sortIcon [field]="'id'"></p-sortIcon></th>
                        <th scope="col" [pSortableColumn]="'email'"><span>Identifiant</span><p-sortIcon [field]="'email'"></p-sortIcon></th>
                        <th scope="col" ><span>Email contact</span></th>
                        <th scope="col" [pSortableColumn]="'dateDemandeProfil'"><span>Date demande</span><p-sortIcon [field]="'dateDemandeProfil'"></p-sortIcon></th>
                        <th scope="col" [pSortableColumn]="'profilUtilisateurId'"><span>Profil Utilisateur</span><p-sortIcon [field]="'profilUtilisateurLibelle'"></p-sortIcon></th>
                        <th scope="col"></th>
                    </tr>
                    
                </ng-template>
                <ng-template pTemplate="body" let-utilisateur>
                    <tr>
                        <td>{{utilisateur.id}}</td>
                        <td>{{utilisateur.email}}</td>
                        <td>{{utilisateur.emailContact}}</td>
                        <td>{{utilisateur.dateDemandeProfil | date:'mediumDate'}}</td>
                        <td>
                            <div *ngIf="utilisateur.profilUtilisateurId">
                                {{utilisateur.profilUtilisateurLibelle}}
                            </div>
                        </td>
                        <td class="text-right">
                            <div class="btn-group flex-btn-group-container"> 
                                <button type="submit"
                                (click)="utilisateurPopup(utilisateur)"
                                        class="btn btn-primary btn-sm">
                                    <span class="fa fa-pencil"></span>
                                    <span class="d-none d-md-inline">Modifier</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>

    </p-tabPanel>





    <p-tabPanel *ngIf="isInstructor == null || isInstructor">
      <ng-template pTemplate="header">Déclarations de mon périmètre</ng-template>
      <div>
        <button type="button" name="export" class="btn btn-primary btn-md float-right" (click)="export(true)" title="Exporter le tableau en CSV">
          Exporter
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        </button>
      </div>
      <div class="mes-declarations-container" *ngSwitchCase="true">
        <span>Nombre de déclarations : {{totalPerimetreDeclarations}}</span>
        <span>    |   Nombre de déclarations par page :
          <select name="nbinPage" id="nbinPage" [(ngModel)]="itemsPerPage" (change)="changeNbInPage($event)" >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
        <div class="table-responsive">
          <p-table [resetPageOnSort]="false" [sortOrder]="2" [value]="perimetre_declarations" class="table table-striped" (onRowExpand)="getOuvrages($event.data)" (onLazyLoad)="loadDeclarationLazy($event)" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalPerimetreDeclarations" dataKey="id" rowExpandMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 3em;"></th>
                <th scope="col" [pSortableColumn]="'id'" style="width: 11em;">N° décl. <p-sortIcon [field]="'id'"></p-sortIcon></th>
                <th scope="col">Type décl.</th>
                <th scope="col">Réglementation </th>
                <th scope="col">Commune(s)</th>
                <th scope="col">Département(s)</th>
                <th scope="col">Région(s)</th>
                <th scope="col" [pSortableColumn]="'date_soumission'">Date de transmission <p-sortIcon [field]="'dateSoumission'"></p-sortIcon></th>
                <th scope="col" [pSortableColumn]="'date_modification'">Date de dernière modification <p-sortIcon [field]="'dateModification'"></p-sortIcon></th>
                <th scope="col">Statut <i class="fas fa-info-circle" [title]="tooltip()"></i></th>
                <th scope="col" style="width: 12em;">Actions</th>
              </tr>
              
            </ng-template>
            <ng-template pTemplate="body" let-declaration let-expanded="expanded">
              <tr [ngClass]="expanded ? 'class-expanded':''">
                <td><a href="#" [pRowToggler]="declaration" >
                    <i [ngClass]="expanded ? 'fas fa-minus-circle' : 'fas fa-plus-circle'" style="font-size: 18px; padding-top: 40%;"></i>
                  </a>
                </td>
                <td>{{declaration.id}}</td>
                <td>{{typedeclarationTitles[declaration.typeDeclarationId]}}</td>
                <td>{{declaration.reglementation}}</td>
                <td><span *ngFor="let commune of declaration.communes">{{commune.nomCom}} ({{commune.codePostal}}){{declaration.communes.indexOf(commune)==declaration.communes.length-1?'':', '}}</span></td>
                <td><span *ngFor="let departement of declaration.departements">{{departement.nomDep}}{{declaration.departements.indexOf(departement)==declaration.departements.length-1?'':', '}}</span></td>
                <td><span *ngFor="let region of declaration.regions">{{region.nomReg}}{{declaration.regions.indexOf(region)==declaration.regions.length-1?'':', '}}</span></td>
                <td>{{declaration.dateSoumission | date:'dd/MM/yyyy'}}</td>
                <td>{{declaration.dateModification | date:'dd/MM/yyyy'}}</td>
                <td><span>{{declaration.statutDeclarationId? declaration.statutDeclarationLibelle:''}}</span></td>
                <td>
                  <div>
                    <button [hidden]="!declaration.dateSoumission" type="button" title="Visualiser la déclaration" (click)="seeDeclaration(declaration)" class="btn btn-primary btn-sm">
                      <span class="fa fa-eye"></span>
                    </button>
                    <button [hidden]="!declaration.dateSoumission || declaration.statutDeclarationId==1950 || !declaration.reglementation.includes('Code minier')" type="button" title="Télécharger la déclaration" (click)="telecharger(declaration)" class="btn btn-secondary btn-sm">
                      <span class="fa fa-download"></span>
                    </button>
                    <button [hidden]="!declaration.dateSoumission || !isForageDomestique(declaration) || declaration.statutDeclarationId==1950" type="button" title="Télécharger le cerfa" (click)="telechargerCerfa(declaration)" class="btn btn-secondary btn-sm" [disabled]="declaration.cerfaLoading">
                      <span [class]="declaration.cerfaLoading?'pi pi-spin pi-spinner':'far fa-copyright'"></span>
                    </button>
                    <button [hidden]="declaration.dateSoumission" *ngSwitchCase="false" type="button" title="Supprimer la déclaration" (click)="deleteDeclaration(declaration)" class="btn btn-danger btn-sm">
                      <span class="fas fa-times"></span>
                    </button>
                    <button style="padding: 0.095rem 0.25rem;" [hidden]="!declaration.auteurFinalisation || !declaration.rapportExists" type="button" (click)="downloadRapport(declaration.id)" class="btn btn-primary btn-sm" title="Télécharger le rapport de fin de forage">
                        <img src="/assets/images/icone-doc-blanc.png" height="26" alt="Rapport de fin de forage" />  
                    </button>
                    <button style="padding: 0.095rem 0.25rem;" *ngIf="declaration.typeDeclarationId != 1800 && declaration.statutDeclarationId==1900 && (!declaration.auteurFinalisation || !declaration.rapportExists)" type="button" (click)="openDialogFinForage(declaration)" class="btn btn-primary btn-sm" title="voir le rapport de fin de forage DIALOG">
                      <img src="/assets/images/icone-doc-orange.png" height="26" alt="Voir le rapport de fin de forage DIALOG" />  
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-declaration>
              <tr class="class-expanded">
                <td [attr.colspan]="8">
                  <table class="table table-striped">
                    <caption>Liste des ouvrages de la déclaration</caption>
                    <thead>
                      <th>Nom Usuel</th>
                      <th>Identifiant BSS</th>
                      <th>Localisation</th>
                      <th>Détails</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ouvrage of ouvrages">
                        <td>{{ouvrage.nomUsuel}}</td>
                        <td>{{ouvrage.indiceBss}}</td>
                        <td>X : {{ouvrage.x}}, Y : {{ouvrage.y}}</td>
                        <td>{{ouvrage.natureOuvrageLibelle}} {{ouvrage.directionLibelle}} sur {{ouvrage.longeur | number}} m</td>
                        <td>
                          <button [hidden]="declaration.statutDeclarationId!=1850 || ouvrage.acteurForeurId" type="button" title="Ajouter le foreur" (click)="modifyOuvrage(ouvrage)" class="btn btn-secondary btn-sm">
                            <span class="fas fa-pen"></span>
                          </button>
                          <!-- <button style="padding: 0.095rem 0.25rem;" [hidden]="account.profilUtilisateurId == 4150 || account.profilUtilisateurId == 4350 || account.profilUtilisateurId == 4400 || account.profilUtilisateurId == 4250" type="button" (click)="openDialog(ouvrage.id)" class="btn btn-primary btn-sm" title="Ouvrir dans DIALOG" [disabled]="!ouvrage.saisieValide">
                            <img src="/assets/images/logo_dialog.svg" height="26" />  
                          </button>-->
                          <button *ngIf="declaration.statutDeclarationId!=1900" type="button" (click)="openPieceJointe(ouvrage.id, ouvrage.nomUsuel, declaration.id)" class="btn btn-primary btn-sm" title="Voir ses pièces jointes">
                            <span class="fas fa-paperclip"></span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="8">
                        Aucune déclaration.
                    </td>
                </tr>
            </ng-template>
          </p-table>

        </div>
      </div>
    </p-tabPanel>





    <p-tabPanel>
      <ng-template pTemplate="header">Mes déclarations</ng-template>
      <div>
        <button type="button" name="export" class="btn btn-primary btn-md float-right" (click)="export(false)" title="Exporter le tableau en CSV">
          Exporter
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        </button>
      </div>
      <div class="mes-declarations-container">
        <span>Nombre de déclarations : {{totalDeclarations}}</span>
        <span>    |   Nombre de déclarations par page :
          <select name="nbinPage" id="nbinPage" [(ngModel)]="itemsPerPage" (change)="changeNbInPage($event)" >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
        <div class="table-responsive">
          <p-table [resetPageOnSort]="false" [sortOrder]="2" [value]="mes_declarations" class="table table-striped" (onRowExpand)="getOuvrages($event.data)" (onLazyLoad)="loadDeclarationLazy($event)" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalDeclarations" dataKey="id" rowExpandMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 3em;"></th>
                <th scope="col" [pSortableColumn]="'id'" style="width: 11em;">N° décl. <p-sortIcon [field]="'id'"></p-sortIcon></th>
                <th scope="col">Type décl.</th>
                <th scope="col">Réglementation </th>
                <th scope="col" [pSortableColumn]="'nom_projet'">Nom projet <p-sortIcon [field]="'nomProjet'"></p-sortIcon></th>
                <th scope="col">Commune(s)</th>
                <th scope="col" [pSortableColumn]="'date_soumission'">Date de transmission <p-sortIcon [field]="'dateSoumission'"></p-sortIcon></th>
                <th scope="col" [pSortableColumn]="'date_modification'">Date de dernière modification <p-sortIcon [field]="'dateModification'"></p-sortIcon></th>
                <th scope="col">Statut <i class="fas fa-info-circle" [title]="tooltip()"></i></th>
                <th scope="col" style="width: 12em;">Actions</th>
              </tr>
              
            </ng-template>
            <ng-template pTemplate="body" let-declaration let-expanded="expanded">
              <tr [ngClass]="expanded ? 'class-expanded':''">
                <td><a href="#" [pRowToggler]="declaration" >
                    <i [ngClass]="expanded ? 'fas fa-minus-circle' : 'fas fa-plus-circle'" style="font-size: 18px; padding-top: 40%;"></i>
                  </a>
                </td>
                <td>{{declaration.id}}</td>
                <td>{{typedeclarationTitles[declaration.typeDeclarationId]}}</td>
                <td>{{declaration.reglementation}}</td>
                <td>{{declaration.nomProjet}}</td>
                <td><span *ngFor="let commune of declaration.communes">{{commune.nomCom}} ({{commune.codePostal}}){{declaration.communes.indexOf(commune)==declaration.communes.length-1?'':', '}}</span></td>
                <td>{{declaration.dateSoumission | date:'dd/MM/yyyy'}}</td>
                <td>{{declaration.dateModification | date:'dd/MM/yyyy'}}</td>
                <td><span>{{declaration.statutDeclarationId? declaration.statutDeclarationLibelle:''}}</span></td>
                <td>
                  <div>
                    <button [hidden]="!declaration.dateSoumission" type="button" title="Visualiser la déclaration" (click)="seeDeclaration(declaration)" class="btn btn-primary btn-sm">
                      <span class="fa fa-eye"></span>
                    </button>
                    <button [hidden]="declaration.dateSoumission" type="button" title="Compléter la déclaration" (click)="modifyDeclaration(declaration)" class="btn btn-secondary btn-sm">
                      <span class="fas fa-pen"></span>
                    </button>
                    <button [hidden]="!declaration.dateSoumission || declaration.statutDeclarationId==1950 || !declaration.reglementation.includes('Code minier')" type="button" title="Télécharger la déclaration" (click)="telecharger(declaration)" class="btn btn-secondary btn-sm">
                      <span class="fa fa-download"></span>
                    </button>
                    <button [hidden]="!declaration.dateSoumission || !isForageDomestique(declaration) || declaration.statutDeclarationId==1950" type="button" title="Télécharger le cerfa" (click)="telechargerCerfa(declaration)" class="btn btn-secondary btn-sm" [disabled]="declaration.cerfaLoading">
                      <span [class]="declaration.cerfaLoading?'pi pi-spin pi-spinner':'far fa-copyright'"></span>
                    </button>
                    <button [hidden]="declaration.dateSoumission" type="button" title="Supprimer la déclaration" (click)="deleteDeclaration(declaration)" class="btn btn-danger btn-sm">
                      <span class="fas fa-times"></span>
                    </button>
                    <button [hidden]="declaration.statutDeclarationId!=1850" type="button" (click)="openFinalisation(declaration.id, declaration.typeDeclarationId, declaration.reglementations)" class="btn btn-success btn-sm" title="Déposer le rapport de fin de travaux">
                      <span class="fas fa-check"></span>
                    </button>
                    <button [hidden]="declaration.statutDeclarationId!=1850" type="button" title="Abandonner la déclaration" (click)="cancelDeclaration(declaration)" class="btn btn-danger btn-sm">
                      <span class="fas fa-ban"></span>
                    </button>
                    <button style="padding: 0.095rem 0.25rem;" [hidden]="!declaration.auteurFinalisation || !declaration.rapportExists" type="button" (click)="downloadRapport(declaration.id)" class="btn btn-primary btn-sm" title="Télécharger le rapport de fin de forage">
                        <img src="/assets/images/icone-doc-blanc.png" height="26" alt="Rapport de fin de forage" />  
                    </button>
                    <button style="padding: 0.095rem 0.25rem;" *ngIf="declaration.typeDeclarationId != 1800 && declaration.statutDeclarationId==1900 && (!declaration.auteurFinalisation || !declaration.rapportExists)" type="button" (click)="openDialogFinForage(declaration)" class="btn btn-primary btn-sm" title="voir le rapport de fin de forage DIALOG">
                      <img src="/assets/images/icone-doc-orange.png" height="26" alt="Voir le rapport de fin de forage DIALOG" />  
                    </button>
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-declaration>
              <tr class="class-expanded">
                <td [attr.colspan]="7">
                  <table class="table table-striped">
                    <caption>Liste des ouvrages de la déclaration</caption>
                    <thead>
                      <th>Nom Usuel</th>
                      <th >Identifiant BSS <i class="fas fa-info-circle" title="L'identifiant unique de l'ouvrage dans la Banque du Sous Sol est délivré lors de la finalisation de la déclaration."></i></th>
                      <th>Localisation</th>
                      <th>Détails</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ouvrage of ouvrages">
                        <td>{{ouvrage.nomUsuel}}</td>
                        <td>{{ouvrage.indiceBss}}</td>
                        <td>X : {{ouvrage.x}}, Y : {{ouvrage.y}}</td>
                        <td>{{ouvrage.natureOuvrageLibelle}} {{ouvrage.directionLibelle}} sur {{ouvrage.longeur | number}} m</td>
                        <td>
                          <button [hidden]="declaration.statutDeclarationId!=1850 || ouvrage.acteurForeurId" type="button" title="Ajouter le foreur" (click)="modifyOuvrage(ouvrage)" class="btn btn-secondary btn-sm">
                            <span class="fas fa-pen"></span>
                          </button>
                          <!-- <button style="padding: 0.095rem 0.25rem;" [hidden]="account.profilUtilisateurId == 4150 || account.profilUtilisateurId == 4350 || account.profilUtilisateurId == 4400 || account.profilUtilisateurId == 4250" type="button" (click)="openDialog(ouvrage.id)" class="btn btn-primary btn-sm" title="Ouvrir dans DIALOG" [disabled]="!ouvrage.saisieValide">
                            <img src="/assets/images/logo_dialog.svg" height="26" />  
                          </button>-->
                          <button *ngIf="declaration.statutDeclarationId!=1900" type="button" (click)="openPieceJointe(ouvrage.id, ouvrage.nomUsuel, declaration.id)" class="btn btn-primary btn-sm" title="Voir ses pièces jointes">
                            <span class="fas fa-paperclip"></span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="7">
                        Aucune déclaration.
                    </td>
                </tr>
            </ng-template>
          </p-table>

        </div>
      </div>
    </p-tabPanel>
    <p-tabPanel>
      <ng-template pTemplate="header">Cartographie</ng-template>
      <ng-template pTemplate="content">
        <ol-map-accueil [ouvrages]="ouvragesMap" [declarations]="mes_declarations" [userEmail]="account.email"></ol-map-accueil>
      </ng-template>
    </p-tabPanel>
    <p-tabPanel *ngSwitchCase="false">
      <ng-template pTemplate="header">Déclarations qui me concernent</ng-template>
      <div>
        <button type="button" name="export" class="btn btn-primary btn-md float-right" (click)="export(false)" title="Exporter le tableau en CSV">
          Exporter
          <i *ngIf="isLoading" class="pi pi-spin pi-spinner" style="position: relative; top: 2px"></i>
        </button>
      </div>
      <div class="mes-declarations-container">
        <span>Nombre de déclarations : {{totalActeurDeclarations}}</span>
        <span>    |   Nombre de déclarations par page :
          <select name="nbinPage" id="nbinPage2" [(ngModel)]="itemsPerPage" (change)="changeNbInPage($event)" >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </span>
        <div class="table-responsive">
          <p-table [resetPageOnSort]="false" [sortOrder]="2" [value]="acteur_declarations" class="table table-striped" (onRowExpand)="getOuvrages($event.data)" (onLazyLoad)="loadDeclarationActeurLazy($event)" [paginator]="true" [lazy]="true" [rows]="itemsPerPage" [totalRecords]="totalActeurDeclarations" dataKey="id" rowExpandMode="single">
            <ng-template pTemplate="header">
              <tr>
                <th scope="col" style="width: 3em;"></th>
                <th scope="col" [pSortableColumn]="'id'" style="width: 11em;">N° déclaration <p-sortIcon [field]="'id'"></p-sortIcon></th>
                <th scope="col" [pSortableColumn]="'nom_projet'">Nom projet <p-sortIcon [field]="'nomProjet'"></p-sortIcon></th>
                <th scope="col">Commune(s)</th>
                <th scope="col" [pSortableColumn]="'date_modification'">Date de modification <p-sortIcon [field]="'dateModification'"></p-sortIcon></th>
                <th scope="col" [pSortableColumn]="'date_finalisation'">Date de finalisation <p-sortIcon [field]="'dateFinalisation'"></p-sortIcon></th>
                <th scope="col">Statut <i class="fas fa-info-circle" [title]="tooltip()"></i></th>
                <th scope="col" style="width: 12em;">Actions</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-declaration let-expanded="expanded">
              <tr [ngClass]="expanded ? 'class-expanded':''">
                <td><a href="#" [pRowToggler]="declaration" >
                    <i [ngClass]="expanded ? 'fas fa-minus-circle' : 'fas fa-plus-circle'" style="font-size: 18px; padding-top: 40%;"></i>
                  </a>
                </td>
                <td>{{declaration.id}}</td>
                <td>{{declaration.nomProjet}}</td>
                <td><span *ngFor="let commune of declaration.communes">{{commune.nomCom}} ({{commune.codePostal}}){{declaration.communes.indexOf(commune)==declaration.communes.length-1?'':', '}}</span></td>
                <td>{{declaration.dateModification | date:'dd/MM/yyyy'}}</td>
                <td>{{declaration.dateFinalisation | date:'dd/MM/yyyy'}}</td>
                <td><span>{{declaration.statutDeclarationId? declaration.statutDeclarationLibelle:''}}</span></td>
                <td>
                  <div>
                    <button type="button" title="Visualiser la déclaration" (click)="seeDeclaration(declaration)" class="btn btn-primary btn-sm">
                      <span class="fa fa-eye"></span>
                    </button>
                    <button [hidden]="!declaration.reglementation.includes('Code minier')" type="button" title="Télécharger la déclaration" (click)="telecharger(declaration)" class="btn btn-secondary btn-sm">
                      <span class="fa fa-download"></span>
                    </button>
                    <!--<button [hidden]="!declaration.dateSoumission || !isForageDomestique(declaration) || declaration.statutDeclarationId==1950" type="button" title="Télécharger le cerfa" (click)="telechargerCerfa(declaration)" class="btn btn-secondary btn-sm" [disabled]="declaration.cerfaLoading">
                      <span [class]="declaration.cerfaLoading?'pi pi-spin pi-spinner':'far fa-copyright'"></span>
                    </button>-->
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-declaration>
              <tr class="class-expanded">
                <td [attr.colspan]="7">
                  <table class="table table-striped">
                    <caption>Liste des ouvrages de la déclaration</caption>
                    <thead>
                      <th>Nom Usuel</th>
                      <th>Identifiant BSS</th>
                      <th>Localisation</th>
                      <th>Détails</th>
                      <th>Action</th>
                    </thead>
                    <tbody>
                      <tr *ngFor="let ouvrage of ouvrages">
                        <td>{{ouvrage.nomUsuel}}</td>
                        <td>{{ouvrage.indiceBss}}</td>
                        <td>X : {{ouvrage.x}}, Y : {{ouvrage.y}}</td>
                        <td>{{ouvrage.natureOuvrageLibelle}} {{ouvrage.directionLibelle}} sur {{ouvrage.longeur | number}} m</td>
                        <td>
                          <button type="button" (click)="openPieceJointe(ouvrage.id, ouvrage.nomUsuel, declaration.id)" class="btn btn-primary btn-sm" title="Voir ses pièces jointes">
                            <span class="fas fa-paperclip"></span>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="isInstructor?8:7">
                        Aucune déclaration.
                    </td>
                </tr>
            </ng-template>
          </p-table>
        </div>
      </div>
    </p-tabPanel>


    
  </p-tabView>
</div>
