import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { VERSION } from '../../app.constants';
import { Utilisateur } from 'src/app/entities/utilisateur/utilisateur.model';
import { Contact } from 'src/app/entities/contact/contact.model';
import { ContactService } from 'src/app/entities/contact/contact.service';
import { LoginService } from 'src/app/shared/login/login.service';
import { Principal } from 'src/app/shared/auth/principal.service';
import { AlertService } from 'src/app/shared/alert/alert.service';
import { LexTypeDeclaration } from 'src/app/entities/lex-type-declaration/lex-type-declaration.model';
import { LexTypeDeclarationService } from 'src/app/entities/lex-type-declaration/lex-type-declaration.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api'; 
import { PrimeNGConfig } from 'primeng/api'; 

@Component({
    selector: 'dup-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.css'
    ],
    providers:[ConfirmationService]
})
export class NavbarComponent implements OnInit {
    inProduction: boolean;
    isAdmin: boolean;
    isState: boolean;
    isInstructor: boolean;

    isNavbarCollapsed: boolean;
    languages: any[];
    swaggerEnabled: boolean;
    modalRef: NgbModalRef;
    version: string;
    lextypedeclarations: LexTypeDeclaration[];

    currentAccount: Utilisateur;
    currentContact: Contact;

    typeDeclaration: string;

    visibiliteDialogRegularisation: false; // Utilisé pour définir la visibilité de la popup régularisation


    constructor(
        private loginService: LoginService,
        private principal: Principal,
        private contactService: ContactService,
        private lexTypeDeclarationService: LexTypeDeclarationService,
        private alertService: AlertService,
        private router: Router,
        private modalService: NgbModal,
        private confirmationService: ConfirmationService, 
        private primengConfig: PrimeNGConfig
    ) {
        this.version = VERSION ? 'v' + VERSION : '';
        this.isNavbarCollapsed = true;
        this.inProduction = false;
        this.swaggerEnabled = false;
        this.isAdmin = false;
        this.isState = false;
    }

    ngOnInit() {         
        this.principal.identity().then((account) => {
            this.currentAccount = account;
            if(account) {
                this.principal.isAdmin().then((res) => {this.isAdmin = res;});
                this.principal.isState().then((res) => {this.isState = res;})
                this.principal.isInstructor().then((res) => {this.isInstructor = res;});
                this.contactService.find(this.currentAccount.contactId).subscribe((contact) => this.currentContact = contact);
                this.lexTypeDeclarationService.query()
                .subscribe((res) => { this.lextypedeclarations = res; }, (res) => this.onError(res));
                }
            this.typeDeclaration = sessionStorage.getItem('typeReglementation');
        });
    }

    collapseNavbar() {
        this.isNavbarCollapsed = true;
    }

    isAuthenticated() {
        return this.principal.isAuthenticated();
    }

    login() {
        window.location.replace('/back/');
    }

    logout() {
        this.collapseNavbar();
        this.loginService.logout();
        this.router.navigate(['']);
    }

    toggleNavbar() {
        this.isNavbarCollapsed = !this.isNavbarCollapsed;
    }

    getImageUrl() {
        return this.isAuthenticated() ? this.principal.getImageUrl() : null;
    }

    openNewDeclaration(typeId:number, typeReglementation: string) {
        /*this.modalRef = this.modalService.open(IdentificationPopupComponent as Component, { size: 'lg', backdrop: 'static', centered: true});
        this.modalRef.result.then((result) => {
            this.router.navigate(['declarationForm',{type: typeId, moa: result.moa, moe: result.moe, proprio: result.proprio, foreur: result.foreur}]);
            this.collapseNavbar();
        }, (reason) => {}); */
        console.log('typeId', typeId);

        if (typeId == 1800) {
            this.confirmationService.confirm(
                {
                    message: 'La régularisation concerne uniquement des ouvrages qui sont anciens dont les travaux remontent à plusieurs années.<BR>Pour les ouvrages plus récents, passez par le menu « Déclarer un nouveau Projet » ',
                    header: 'ATTENTION',
                    icon: 'pi pi-exclamation-triangle',
                    acceptLabel: 'Valider',
                    rejectLabel: 'Annuler',
                    rejectButtonStyleClass: 'p-button-secondary',
                    accept: () => {
                        sessionStorage.setItem('typeReglementation', typeReglementation);
                        this.router.navigate(['declarant',{type: typeId}]);
                    },
                    reject: () => {
                        this.router.navigate(['/']);
                    }
                }
            );
        } else {
            sessionStorage.setItem('typeReglementation', typeReglementation);
            this.router.navigate(['declarant',{type: typeId}]);
        }
        
    }
    
    // gestion des erreurs de communication
  private onError(error: any) {
    this.alertService.error(error.message, null, null);
  }
}
