import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SERVER_API_URL } from '../../app.constants';
import { PieceJointe } from './piece-jointe.model';
import { DateUtils } from 'src/app/shared/model/date-utils';


@Injectable()
export class PieceJointeService {

    private resourceUrl =  SERVER_API_URL + 'api/piece-jointes';

    constructor(private http: HttpClient, private dateUtils: DateUtils) { }

    create(pieceJointe: FormData): Observable<PieceJointe> {
        return this.http.post(this.resourceUrl, pieceJointe).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    createRapport(pieceJointe: FormData): Observable<PieceJointe> {
        return this.http.post(`${this.resourceUrl}/rapport`, pieceJointe).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    createOrUpdateRapport(pieceJointe: FormData): Observable<PieceJointe> {
        return this.http.post(`${this.resourceUrl}/rapport/update`, pieceJointe).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    update(pieceJointe: PieceJointe): Observable<PieceJointe> {
        const copy = this.convert(pieceJointe);
        return this.http.put(this.resourceUrl, copy).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    find(id: number): Observable<PieceJointe> {
        return this.http.get(`${this.resourceUrl}/${id}`).pipe(map((res) => {
            return this.convertItemFromServer(res);
        }));
    }

    findByOuvrage(id: number): Observable<PieceJointe[]> {
        return this.http.get(`${this.resourceUrl}/ouvrage/${id}`).pipe(map((res) => {
            return this.convertResponse(res);
        }));
    }

    query(req?: any): Observable<PieceJointe[]> {
        return this.http.get(this.resourceUrl, {params: req}).pipe(
            map((res) => this.convertResponse(res)));
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }

    downloadFile(id:number):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(`${this.resourceUrl}/${id}/dl`, { headers: headers, responseType: 'blob' });
    }

    downloadRapport(id:number):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(`${this.resourceUrl}/${id}/rapport`, { headers: headers, responseType: 'blob' });
    }

    rapportExists(id:number):Observable<any> {
        let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json');
        return this.http.get(`${this.resourceUrl}/${id}/rapport-exists`, { headers: headers, responseType: 'text' });
    }

    private convertResponse(res): PieceJointe[] {
        const result = [];
        for (let i = 0; i < res.length; i++) {
            result.push(this.convertItemFromServer(res[i]));
        }
        return result;
    }

    /**
     * Convert a returned JSON object to PieceJointe.
     */
    private convertItemFromServer(json: any): PieceJointe {
        const entity: PieceJointe = Object.assign(new PieceJointe(), json);
        entity.dateCreation = this.dateUtils
            .convertLocalDateFromServer(json.dateCreation);
        entity.dateModification = this.dateUtils
            .convertLocalDateFromServer(json.dateModification);
        return entity;
    }

    /**
     * Convert a PieceJointe to a JSON which can be sent to the server.
     */
    private convert(pieceJointe: PieceJointe): PieceJointe {
        const copy: PieceJointe = Object.assign({}, pieceJointe);
        copy.dateCreation = this.dateUtils
            .convertLocalDateToServer(pieceJointe.dateCreation);
        copy.dateModification = this.dateUtils
            .convertLocalDateToServer(pieceJointe.dateModification);
        return copy;
    }
}
